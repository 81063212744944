import React from 'react'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import './priceplan.css'
import { useSelector } from 'react-redux';
const PricePlan = () => {
  const {userip}=useSelector(state=>state.getuserIpReducer)
  let basic;
  if(userip==='PK'){
    basic='PKR-15,000/-'
  }
  else if(userip==='FR') { 
    basic='EURO-50'
  }
  else if(userip==='AE') { 
    basic='AED-300'
  }
  else if(userip==='SA') { 
    basic='SAR-300'
  }
  else {
    basic='$60'
  }
  let secondary;
  if(userip==='PK'){
    secondary='PKR-30,000/-'
  }
  else if(userip==='FR') { 
    secondary='EURO-100'
  }
  else if(userip==='AE') { 
    secondary='AED-500'
  }
  else if(userip==='SA') { 
    secondary='SAR-500'
  }
  else {
    secondary='$100'
  };
  let ecom;
  if(userip==='PK'){
    ecom='PKR-50,000/-'
  }
  else if(userip==='FR') { 
    ecom='EURO-500'
  }
  else if(userip==='AE') { 
    ecom='AED-1000'
  }
  else if(userip==='SA') { 
    ecom='SAR-1000'
  }
  else {
    ecom='$500'
  }
      




  return (
 <>


  <div className="pic-pic text-center">
Our Top Plans

    </div>

  
    <div className="p-0  justify-content-center ppr">
      {/* price plan 1 */}
   
<div className="static ms-3">
<button className=" btn static-title text-center text-light" style={{backgroundColor:'#543AA3',}}><h3>STATIC</h3></button>
{/* <div className="btn static-price text-light text-center"style={{backgroundColor:'#5739B9'}}><h2>{basic}</h2></div> */}
<div className="static-detail">
<ol className='fline'> 
<li>10 to 50 Pages</li>
<li>Custom Design</li>
<li>Completely Responsive</li>
<li>SEO Friendly</li>

<li>Google Analytice Integration</li>
<li>Social Media Sharing Options</li>
<li>1 year Free Hosting</li>
<li>1 year Free Domain</li>
<li>SSL Certificate</li>


</ol>
  
</div>
<div className="static-button text-center">
{/* <button className='btn mt-2  mb-2 text-center text-light'style={{background:'#EB600F'}}>Choose Plan</button> */}

</div>

</div>

   
   
    {/* price plan 2 */}
      
<div className="static">
<button className=" btn static-title text-center text-light" style={{backgroundColor:'#80A814',}}><h3>DYNAMIC</h3></button>
{/* <div className="btn static-price text-light text-center"style={{backgroundColor:'#92C703',}}><h2>{secondary}</h2></div> */}
<div className="static-detail">
<ol className='fline'>
<li>Unlimited Pages</li>
<li>Custom Design</li>
<li>Completely Responsive</li>
<li>SEO Friendly</li>

<li>Google Analytice Integration</li>
<li>Social Media Sharing Options</li>
<li>1 year Free Hosting</li>
<li>1 year Free Domain</li>
<li>SSL Certificate</li>


</ol>
  
</div>
<div className="static-button text-center">
{/* <button className='btn  mt-2  mb-2 text-center text-light'style={{background:'#EB600F'}}>Choose Plan</button> */}
</div>



    </div>
    {/* price plan 3 */}

<div className="static">
<button className=" btn static-title text-center text-light"style={{backgroundColor:'#C76025',}}><h3>Dynamic (Ultimate)</h3></button>
{/* <div className="btn static-price text-light text-center"style={{backgroundColor:'#EB600F',}}><h2>{ecom}</h2></div> */}
<div className="static-detail">
<ol>
<li>Unlimited Pages</li>
<li>Custom Design</li>
<li>Completely Responsive</li>
<li>SEO Friendly</li>
<li>payment Gateways</li>
<li>Google Analytice Integration</li>
<li>Social Media Sharing Options</li>
<li>1 year Free Hosting</li>
<li>1 year Free Domain</li>
<li>SSL Certificate</li>
<li>Dashboard</li>


</ol>
  
</div>
<div className="static-button text-center">
{/* <button className='btn mt-2  mb-2 text-center text-light' style={{background:'#EB600F'}}>Choose Plan</button> */}
</div>

</div>

    
    </div>



 </>
  )
}

export default PricePlan
