import React, { useEffect } from 'react'
import Carousel from 'react-material-ui-carousel'
import { Link } from 'react-router-dom'
import { getPictuerAction, getsinglePictureAction } from '../redux/action/pictureAction'
import { useDispatch, useSelector } from 'react-redux'
const Pos = () => {
  const {picture}=useSelector(state=>state.getPictureReducer)
  // console.log(picture);
const dispatch=useDispatch()

  useEffect(() => {
 dispatch(getPictuerAction())
  }, [dispatch])
  
  return (
    <>
    <div className="container-fluid">
        <div className="row text-center object-fit-cover">

      <h3 className='pic-pic'>our point of sale software</h3>
    <Carousel
                className="carasousel mt-4"
                autoPlay={true}
                animation="fade"
                indicators={false}
                navButtonsAlwaysVisible={true}
                cycleNavigation={true}
                duration='3s'
                navButtonsProps={{
                   
                    // style: {
                    //     background: "#fff",
                    //     color: "#494949",
                    //     borderRadius: 100,
                    //     border:10,
                    //     borderColor:'#494949',
                    //     marginTop: -22,
                    //     height: "104px",
                    // }
                }}>
                  

  {
    picture?picture.map((item)=>{
        return(
            <>
            <div>
                <Link to={`/pos/image/${item._id}`} target='_blank'>
          <img src={`/uploads/posurdu/${item.image}`}
          className="card-img-top img-fluid banner-image"
                 onClick={()=>dispatch(getsinglePictureAction(item.id))}
          />
          </Link>
            </div>
            </>
        )
    }):<h5>no data found</h5>
  }
  </Carousel>
  </div>
    </div>
  </>
  )
}

export default Pos