import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleDetailsAction } from '../redux/action/deatilsAction'
import { Link, useParams } from 'react-router-dom'

const CombineServiceId = () => {
    const {id,slug}=useParams()
    // console.log(id,slug);
    const {details}=useSelector(state=>state.getSingleDetailsReducer)
    console.log(details);
    const dispatch=useDispatch()
    useEffect(() => {
   dispatch(getSingleDetailsAction(id))
    }, [dispatch,id])
    
  return (
  <>
<div className="container-fluid">
    <div className="row">
    <div className="card w-100 h-100">
             
             
                <img className="card-img-top object-fit-cover mx-auto mt-2"
                src={`/uploads/pointofsale/${details.image}`} 
                alt={details.name} style={{height:'330px', width:'330px'}}
               
                />
               <div className="card-body">
                <div className="card-title text-capitalize font-family-roman text-center fw-bold text-danger text-center" 

                >{details.title}</div>
                {details.details}
               </div>
               
             </div>
                    <button className='btn btn-success'><Link to={`/update/service/${slug}/${id}`} style={{color:'black'}}>update</Link> </button>
     </div></div>

  
  </>
  )
}

export default CombineServiceId