import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateWellcomeAction } from '../redux/action/wellcomeAction'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { TextareaAutosize } from '@mui/base/TextareaAutosize';


const UpdateWellcome = () => {
    const {id}=useParams()
    // console.log(id,'para');
    const dispatch=useDispatch()
    const alert=useAlert()
    const {wellcome}=useSelector(state=>state.getWellcomeReducer)
    const {updatewellcome,loading}=useSelector(state=>state.updateWellcomeReducer)

    const [description, setDescription] = useState(wellcome.description)
    const [name, setName] = useState(wellcome.name)
    const [subtitle, setSubTitle] = useState(wellcome.name)
    const postData=async()=>{
        dispatch(updateWellcomeAction({id,description,name,subtitle}))
        if(loading===false){
            alert.success('data updated successfully')        }
        else{
            alert.error('upload failed')
        }
    }
    useEffect(() => {
        dispatch(updateWellcomeAction())
      }
    , [dispatch])
    
  return (
   <>
<label htmlFor="title">Title:</label>
<input type="text" className='m-2 w-50' placeholder='title' value={name}  onChange={(e)=>setName(e.target.value)}/><br/>
<label htmlFor="subtitle">Subtitle:</label>
<input type="text" className='m-2 w-50' placeholder='title' value={subtitle}  onChange={(e)=>setSubTitle(e.target.value)}/><br/>
{/* <label htmlFor="description">Description</label> */}
   <TextareaAutosize placeholder='description' className='w-75 m-5' value={description}  onChange={(e)=>setDescription(e.target.value)}/><br/>
   <button className='btn btn-success' onClick={postData}>update</button>
    
   </>
  )
}

export default UpdateWellcome