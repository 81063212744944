import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import './Deatils.css'

const Details = () => {
    const {details}=useSelector(state=>state.getDetailsReducer)
    // console.log(details,'details');
     const {slug}=useParams()
     console.log(slug,'slug');
    //  <Link to={`/service/${slug}/${item._id}`}
    
  return (
  <>
<div className="det-div">
  {details.map((item,i)=>(
    <>
<div className="details-inner-div">
  <div className="details-image-div">
  <img className="card-img-top w-50 mx-auto mt-3"
  src={`/uploads/pointofsale/${item.image}`} alt={item.title}/>
  </div>
  <div className="details-name-div">
  ({i+1})..{item.title}
  </div>
  <div className="details-deatils-div">
  {item.details.length>=200?<div>{item.details.substring(0,200)}...</div>:item.details}
  </div>
  <button className='btn btn-warning'><Link to={`/service/${slug}/${item._id}`} style={{color:'black'}}> Read More</Link></button>
</div>
    </>
  ))}
    </div>

  </>
  )
}

export default Details