import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { loadUser, postLOGINAction, postLoginAction } from '../../redux/action/usersAction'

import {useDispatch, useSelector} from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'
const Login = () => {
  const {userInfo}=useSelector(state=>state.loadUserReducer)
  const Navigate=useNavigate()
  const dispatch=useDispatch()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
const postData=async()=>{
    dispatch(postLoginAction({email:email,password:password}))
    
}
useEffect(() => {
dispatch(postLoginAction())
dispatch(loadUser())
}, [dispatch])


  return (
<>
<input placeholder='email' type='email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
<input placeholder='password' type='password' value={password} onChange={(e)=>setPassword(e.target.value)}/>

<button className='btn btn-info' onClick={postData}>submit</button>
</>
  )
}

export default Login