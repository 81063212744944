import React, { useState } from 'react'
import './contact.css'
import axios from 'axios'
import Div from '../div/Div'
const Contact = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const postContact=async(e)=>{
// e.preventDefault()
console.log(name,subject,message,email,'name,subject,message,email');
let postdata=({name,subject,message,email});
await axios.post('/contact',{name,subject,message,email})
    }
  return (
 <>
 {/* <!--Section: Contact v.2--> */}



 <h3 className="pic-pic">Contact us
    {/* <Div/> */}
    </h3>
    <div className="container-fluid w-100">


<div className="row">
<section className="mb-4 text-light contact">

    {/* <!--Section heading--> */}
  
    {/* <!--Section description--> */}
    <p className="text-center w-responsive mx-auto mt-3 bg-dark">Do you have any questions? Please do not hesitate to contact us directly. Our team will come back to you within
        a matter of hours to help you.</p>

    <div className="row">

        {/* <!--Grid column--> */}
        <div className="col-md-12 mb-md-0 mb-5">
            <form id="contact-form" name="contact-form" action="" method="POST" className='ms-3'>

                {/* <!--Grid row--> */}
                <div className="row text-center">

                    {/* <!--Grid column--> */}
                    <div className="col-md-6 text-center">
                        <div className="md-form mb-0 text-center">
                        <label for="name" className="">Your name</label>
                            <input type="text" id="name" name="name" value={name} onChange={(e)=>setName(e.target.value)} className="form-control"/>
                          
                        </div>
                    </div>
                    {/* <!--Grid column--> */}

                    {/* <!--Grid column--> */}
                    <div className="col-md-6">
                        <div className="md-form mb-0">
                        <label for="email" className="">Your email</label>
                            <input type="text" id="email" name="email" className="form-control"
                            value={email} onChange={(e)=>setEmail(e.target.value)}
                            />
                           
                        </div>
                    </div>
                    {/* <!--Grid column--> */}

                </div>
                {/* <!--Grid row--> */}

                {/* <!--Grid row--> */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="md-form mb-0">
                        <label for="subject" className="">Subject</label>
                            <input type="text" id="subject" name="subject" className="form-control"
                            value={subject} onChange={(e)=>setSubject(e.target.value)}
                            />
                      
                        </div>
                    </div>
                </div>
                {/* <!--Grid row--> */}

                {/* <!--Grid row--> */}
                <div className="row">

                    {/* <!--Grid column--> */}
                    <div className="col-md-12">

                        <div className="md-form">
                        <label for="message">Your message</label>
                            <textarea type="text" id="message" name="message" rows="2" className="form-control md-textarea"
                            value={message} onChange={(e)=>setMessage(e.target.value)}
                            
                            ></textarea>
                            
                        </div>

                    </div>
                </div>
                {/* <!--Grid row--> */}

            </form>

            <div className="text-center text-md-left m-5z">
                <a className="btn btn-primary" onClick={postContact}>Send</a>
            </div>
            <div className="status"></div>
        </div>
        {/* <!--Grid column--> */}

        {/* <!--Grid column--> */}
  
        {/* <!--Grid column--> */}

    </div>

</section>
{/* <!--Section: Contact v.2--> */}
 
</div>
 </div>
 </>
  )
}

export default Contact