import React, { useEffect } from 'react'
import './services.css'
// import data from './servicedata.json'
import axios from 'axios'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Div from '../div/Div'
import {useDispatch, useSelector} from 'react-redux'
import { getFeatureAction, getserviceAction, updateService, updateServiceAction } from '../redux/action/serviceAction'
import { getservice2nd, getserviceById } from '../redux/action/newServiceAction'
import { ListItem } from '@mui/material'
import { getDetailsAction } from '../redux/action/deatilsAction'
import GoogleIcon from '@mui/icons-material/Google';

const Services = () => {
  const {service}=useSelector(state=>state.getServiceReducer)
  const user={
  role:'admin'
  }
  const navigate=useNavigate()
   
  const addPost=(e)=>{
    console.log(e,'e e');
dispatch(getserviceById(e))
dispatch(getservice2nd(e))
dispatch(getDetailsAction(e))
dispatch(getFeatureAction(e))
navigate(`/service/${e}`)
  }
  // console.log(service);
  const dispatch=useDispatch()
  useEffect(() => {
  dispatch(getserviceById())
  dispatch(getservice2nd())
  dispatch(getserviceAction())
  dispatch(updateServiceAction())
  dispatch(getFeatureAction())
  dispatch(getDetailsAction())
  }, [dispatch])
  
  // console.log(data,'service');
  return (

      <div className="services">
      <div className="pic-pic text-center">
OUR SERVICES

    </div>
{/* <Div/> */}
<div className="services-div">
{
service && service.length>0 ? service.map((data,i)=>{
  
  return(
    <>
<div className="image-inner-div"  onClick={()=>addPost(data.slug)}
style={{cursor:'pointer'}}>
    <div className="images-div">
    <img className="card-img-to object-fit-fill" 
  src={`/uploads/services/${data.image}`} 
  alt="Card cap"
  // onClick={()=>dispatch(getserviceById(data.slug))}
  
  />
       </div>
    <div className="details-div">
    <h5 className="text-center  fw-bold text-light ser-name" >{data.name}</h5>
    <p className="card-text fw-light div-main border-bottom-0 border-top-0" style={{fontSize:'13px'}}>

    {data.details.lenght>300?<div>{data.details.substring(0,300)}...</div>:<div className='fw-bold letter-spacing-2 font-family-roman'>{data.details.substring(0,200)}...</div>}</p>
    

    </div>
    </div>
     
    </>
  )
 }):null
}


{/* services 3 */}


</div>
</div>


  )
}

export default Services