import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { registerUser } from '../redux/action/usersAction'

const Register = () => {
    const [name, setName] = useState('muhammad sajjad')
    const [email, setEmail] = useState('s@s.com')
    const [password, setPassword] = useState('123456')
    const [image, setImage] = useState([])
    const dispatch=useDispatch()
    const postData=()=>{
const formData=new FormData()
formData.append('name',name)
formData.append('email',email)
formData.append('password',password)
formData.append('image',image)
console.log(image,'image');
dispatch(registerUser(formData))
    }
    const tech=(e)=>{
    //    if(e.target.value===5){
console.log(Number(e.target.value*`${6}`));
    //    }
    }
  return (
  <>
  <div className="container">
    <div className="row">
        <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-row m-3">
<div className="col-2"><label htmlFor="name">Name:</label> </div>
<div className="col-10">
    <input type='text' className='w-100 m2 bd' value={name} onChange={(e)=>setName(e.target.value)}/> </div></div>
        
        <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-row m-3">
<div className="col-2"><label htmlFor="email">E-Mail:</label> </div>
<div className="col-10">
    <input type='email' className='w-100 m2 bd' value={email} onChange={(e)=>setEmail(e.target.value)}/> </div></div>
        
        <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-row m-3">
<div className="col-2"><label htmlFor="password">Password</label> </div>
<div className="col-10">
    <input type='password' className='w-100 m2 bd' value={password} onChange={(e)=>setPassword(e.target.value)}/> </div></div>
        
        <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-row m-3">
<div className="col-2"><label htmlFor="picture">Picture</label> </div>
<div className="col-10">
    <input type='file' className='w-100 m2 bd' name='image' onChange={(e)=>setImage(e.target.files[0])}/> </div></div>
    <input type='text' className='w-100 m2 bd' name='i' onChange={(e)=>tech(e)}/> </div>
        
    
    <button className='btn btn-primary w-50' type='submit' onClick={postData}>submit</button>
    
    </div>

  </>
  )
}

export default Register