export const POST_DISTRICT_REQUEST="POST_DISTRICT_REQUEST"
export const POST_DISTRICT_SUCCESS="POST_DISTRICT_SUCCESS"
export const POST_DISTRICT_FAIL="POST_DISTRICT_FAIL"

export const GET_DISTRICT_REQUEST="GET_DISTRICT_REQUEST"
export const GET_DISTRICT_SUCCESS="GET_DISTRICT_SUCCESS"
export const GET_DISTRICT_FAIL="GET_DISTRICT_FAIL"

export const GET_ALL_DISTRICT_REQUEST="GET_ALL_DISTRICT_REQUEST"
export const GET_ALL_DISTRICT_SUCCESS="GET_ALL_DISTRICT_SUCCESS"
export const GET_ALL_DISTRICT_FAIL="GET_ALL_DISTRICT_FAIL"