import React, { useEffect } from 'react'
import './wellcome.css'
import Div from '../div/Div'
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getWellcomeAction, updateWellcomeAction } from '../redux/action/wellcomeAction'
const Wellcome = () => {
  const {wellcome}=useSelector(state=>state.getWellcomeReducer)
  console.log(wellcome,'well come');
  // const {userInfo}=useSelector(state=>state.loadUserReducer)
  const userInfo={
    role:'admin'
  }
  // console.log(wellcome,'well come');
  const dispatch=useDispatch()
  useEffect(() => {
    dispatch(getWellcomeAction())
    dispatch(updateWellcomeAction())
  
  }, [dispatch])
  
 
  return (
  <>
<div className="container-fluid wellcome-main-div">
    <div className="row">
    {/* <h3 className='text-center text-capitalize main-feature'>{wellcome&&wellcome.name}</h3> */}

    <div className="pic-pic text-center">
    {/* {wellcome&&wellcome?.name} */}
    WellCome To Unique SoftWare House

    </div>
    {/* <Div/> */}
    {/* <h3 className='text-capitalize wellcome-note'>{wellcome?wellcome.subtitle:<h5>wellcome to smart programing</h5>}</h3> */}
<div className="wellcome">
    <div className="row">
           <div className="text-center company-img">
            <img src='./logo/logo.jpg'
             alt='smart programer' style={{height:'100px',width:'120px'}}/>


           </div>
           <div className="name-class text-center mt-2 fw-bold text-capitalize">{wellcome?.name}</div>
        <div className="des mt-3 fw-bold">
 {wellcome?wellcome.description:<h6>description</h6>}
{/*         
        {userInfo.role==='admin'?<button className='update'
        // onClick={()=>dispatch(updateWellcomeAction(wellcome._id))}
        ><Link to={`/update/wellcome/${wellcome._id}`} className='update1' style={{color:'white'}}>Edit</Link></button>:null}
         */}
        </div>
        </div>
    </div>
    </div>
</div>
  
  </>
  )
}

export default Wellcome