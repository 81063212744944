import React from 'react'
import { Link } from 'react-router-dom'
import AddBanner from '../banner/AddBanner'

const Dashboard = () => {
  return (
 <>
 <div className="container-fluid">
    <div className="row d flex flex-row">
    <div className="col-3">
    <ul>
        <li>
<Link to='/add/banner'>Add Banner</Link>
        </li>
    
    
    </ul>
 
 
 
 </div>
 <div className="col-9">


 </div>

    </div>
 </div>
 
 </>
  )
}

export default Dashboard