import axios from 'axios'
import { BANNER_UPLOAD_FAIL, BANNER_UPLOAD_REQUEST, BANNER_UPLOAD_SUCCESS,
    GET_BANNER_FAIL, GET_BANNER_REQUEST, GET_BANNER_SUCCESS,
    DELETE_BANNER_FAIL, DELETE_BANNER_REQUEST, DELETE_BANNER_SUCCESS
} from '../constant/BannerConstant';
export const postBannerAction = (bannerData) => async(dispatch)=>{
    console.log(bannerData,'banner data');
dispatch({type:BANNER_UPLOAD_REQUEST})
try {
    const {data,message}=await axios.post('/admin/add/banner',bannerData)
dispatch({type:BANNER_UPLOAD_SUCCESS,
    payload:data,message})
} catch (error) {
    dispatch({type:BANNER_UPLOAD_FAIL,
        payload: error.response.data,
    })}}
    //get banner
    export const getBannerAction = () => async(dispatch)=>{
        dispatch({type:GET_BANNER_REQUEST})
        try {
            const {data}=await axios.get('/get/banner')
            console.log(data.banner,'banner get data');
        dispatch({type:GET_BANNER_SUCCESS,
            payload:data.banner})
        } catch (error) {
            dispatch({type:GET_BANNER_FAIL,
                payload: error
            })}}
