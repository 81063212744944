export const GET_DETAILS_REQUEST = "GET_DETAILS_REQUEST";
export const GET_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
export const GET_DETAILS_FAIL = "GET_DETAILS_FAIL";

export const GET_SINGLE_DETAILS_REQUEST = "GET_DETAILS_REQUEST";
export const GET_SINGLE_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
export const GET_SINGLE_DETAILS_FAIL = "GET_DETAILS_FAIL";

export const UPDATE_SINGLE_DETAILS_REQUEST = "GET_DETAILS_REQUEST";
export const UPDATE_SINGLE_DETAILS_SUCCESS = "GET_DETAILS_SUCCESS";
export const UPDATE_SINGLE_DETAILS_FAIL = "GET_DETAILS_FAIL";