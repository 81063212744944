import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { postServiceAction } from '../redux/action/serviceAction'
import {useAlert} from 'react-alert'
import { TextareaAutosize } from '@mui/material'
const NewService = () => {
  const alert=useAlert()
  const {service}=useSelector(state=>state.getServiceReducer)


  // console.log(servic,'success');
    const [name, setName] = useState('')
    const [credit, setCredit] = useState('')
    const [details, setDetails] = useState('')
    const [image, setImage] = useState([])


const dispatch=useDispatch()
useEffect(() => {
dispatch(postServiceAction)
}, [dispatch])

const postData=async(e)=>{
  e.preventDefault()
  const  formData= new FormData()
  formData.append('name',name)
  formData.append('image',image)
  formData.append('details',details)
  formData.append('credit',credit)

  dispatch(postServiceAction(formData))
}






// console.log(serviveId);
  return (
    <>
      <form className='bg-info'>
        <div className='w-50 d-flex flex-row'>
    
  <div className="form-group m-3">
    <label for="Image">select Image</label>
    <input type="file" className="form-control-file" id="exampleFormControlFile1" name='image'
    onChange={(e)=>setImage(e.target.files[0])}
    />
  </div></div>
   <div className="form-group m-3 w-50">
    <label for="name">Name</label>
    <input type="text" className="form-control" id="formGroupExampleInput" placeholder="name"
    value={name} onChange={(e)=>setName(e.target.value)}/>
  </div>
   
   <div className="form-group m-3 w-50">
    <label for="Details">Details</label>
    <TextareaAutosize type="text" className="form-control" id="formGroupExampleInput" placeholder="Details"
    value={details} onChange={(e)=>setDetails(e.target.value)}/>
  </div>
   <div className="form-group m-3 w-50">
    <label for="Description">Description</label>
    <TextareaAutosize className="form-control" id="formGroupExampleInput" placeholder="Description"
    value={credit} onChange={(e)=>setCredit(e.target.value)}/>
  </div>
  <button type="submit" className="btn btn-primary mb-5 m-3 w-25 text-center"
  onClick={postData}>Submit</button>
  </form>
    </>
  )
}

export default NewService