import {     GET_FINAL_FAIL, GET_FINAL_REQUEST, GET_FINAL_SUCCESS,
    GET_SINGLE_FINAL_FAIL, GET_SINGLE_FINAL_REQUEST, GET_SINGLE_FINAL_SUCCESS,
} from '../constant/finalConstant';
export const postFinalReducer = (state = { final: [] }, action) => {
    switch (action.type) {
        case GET_FINAL_REQUEST:
         
            return {
              loading: true,
              final: [],
            }

case GET_FINAL_SUCCESS:
    
    return{
        loading:false,
        final:action.payload,
    }
    case GET_FINAL_FAIL:
        console.log(action.payload);
        return{
            loading:false,
            final:action.payload
        }


            default:
                return state;}}

export const getSingleFinalReducer = (state = { final: {} }, action) => {
    switch (action.type) {
        case GET_FINAL_REQUEST:
         
            return {
              loading: true,
              final: {},
            }

case GET_FINAL_SUCCESS:
    
    return{
        loading:false,
        final:action.payload,
    }
    case GET_FINAL_FAIL:
        console.log(action.payload);
        return{
            loading:false,
            final:action.payload
        }


            default:
                return state;}}