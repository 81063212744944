import React from 'react'
import { Link } from 'react-router-dom'
import './whatsapp.css'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
const WhatsApp = () => {
  return (
    <>
  
            <div className="wa">
               <Link to='http://wa.me/923144763232' target='_blank'>
                <WhatsAppIcon className='wap' style={{ height:'70px',width:'70px',margin:'-100px',color:'green'}}/></Link>
            </div>
   
    
    </>
  )
}

export default WhatsApp