import React, { useEffect, useState } from 'react'
import Dashboard from '../Dashboard/Dashboard'
import { useDispatch } from 'react-redux'
import { postBannerAction } from '../redux/action/bannerAction'

const AddBanner = () => {
    const dispatch=useDispatch()
    const [image, setImage] = useState('')
    const [name, setName] = useState('')
    const postData=(e)=>{
        e.preventDefault()
        const formData=new FormData()
        formData.append('name',name)
        formData.append('image',image)
dispatch(postBannerAction(formData))


    }
    useEffect(() => {
dispatch(postBannerAction())
    }, [dispatch])
  return (
    <>

      <form className='bg-info'>
      <div className="form-group w-25 m-3">
    <label for="formGroupExampleInput">Name</label>
    <input type="text" className="form-control w-26" id="formGroupExampleInput" placeholder="name"
    value={name} onChange={(e)=>setName(e.target.value)}
    />
  </div>
      <div className="form-group m-3">
    <label for="exampleFormControlFile1">Choose Your File</label>
    <input type="file" className="form-control-file" id="exampleFormControlFile1"
    name='image' onChange={(e)=>setImage(e.target.files[0])}
    />
  </div>
  <button type="submit" className="btn btn-primary m-4" onClick={postData}>Submit</button>
  </form>
    
    </>
  )
}

export default AddBanner