import React from "react";
import data from '../Team.json'
import './Team.css'
import { useNavigate } from "react-router-dom";
const Team = () => {
  const navigate=useNavigate()
  const getTeam=(e)=>{
navigate(`/team/${e}`)
  }
  return (
    <>
      <div className="contain-fluid m-2">
        <div className="row">
          <h3 className="pic-pic">Our Great Team</h3>
        Unique Software House (USH) is known as the well-established software house in Pakistan since 2017.
          We started our journey on September 2017 with an ambition to provide
          best-quality web services in Pakistan and develop a trustworthy
          strategic relationship. Due to new inventions in technology, all
          barriers of communication are eliminated and the world has become a
          global village.Unique Software House (USH) is well respected and
          considered as the top-ranked software house in Pakistan.Unique Software House (USH)
          is providing a wide range of services including website development,
          web designing, mobile apps development, search engine optimization
          (SEO), digital marketing including social media marketing (SMM) and
          much more.
        </div>
      </div>
      <div className="team-div">
        
{
data.map((item,i)=>{
  
  return(
    <>

    <h1>Team is on hold All details will be available in 48 hours</h1>
{/* <div className="team-inner-div" key={i} onClick={()=>getTeam(item.name)} style={{cursor:'pointer'}}>
    <div className="team-image-div">
    <img className="card-img-to object-fit-fill" 
  src={item.image} alt={item.images}/>
       </div>
        <div className="team-name" style={{background:'#543AA3'}}>{item.name}</div>

    </div> */}
    </>
  )})}
</div>
</>
  )}


export default Team;
