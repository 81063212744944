import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import { getcomservice, getimageservice } from '../redux/action/newServiceAction';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './services.css'
import Details from './Details';
import { getSingleFeatureAction } from '../redux/action/serviceAction';
const CombineServices = () => {
    // const params=useParams()
    const navigate=useNavigate()
    // console.log(params,'params')
    const dispatch=useDispatch()
    const {service}=useSelector(state=>state.combineService)
    console.log(service,'service');
    const service2nd=useSelector(state=>state.combineService2nd.service)
    // console.log(service2nd,'kdjlghk hglkh dhgld hdgl dhgld g');
    const {feature}=useSelector(state=>state.getFeatureReducer)
    const {wellcome}=useSelector(state=>state.getWellcomeReducer)
    // const {userInfo}=useSelector(state=>state.loadUserReducer)
    const userInfo={
      role:'admin'
    }
const postData=(id)=>{
  console.log(id,'id id id');
  dispatch(getSingleFeatureAction(id))
}
const addPost=(e)=>{
  console.log(e,'eeeeeeeeee');
  navigate(`/services/combine/${e}`)
}
    // console.log(service2nd,'shfjkbh');
    // onClick={()=>{dispatch(getcomservice(item._id));dispatch(getimageservice(item._id));addPost(item.slug)}}
    useEffect(() => {
      dispatch(getcomservice())
      dispatch(getimageservice())
      window.scrollTo(0, 0); 
    }, [dispatch])
    
    // console.log(service);
  return (
    <>
    <div className="container-fluid">
        
        <div className="s-services d-flex flex-row mx-auto">
        <div className="row " >
          <h3 className='main-feature'>services these We Offer</h3>

          <div className="services-div">
        {service2nd && service2nd.map((item)=>
            (
                <>
                <div className="image-inner-div text-center align-item-center"
                onClick={()=>{dispatch(getcomservice(item._id));dispatch(getimageservice(item._id));addPost(item.slug)}}
                style={{cursor:'pointer'}}>
    <div className="images-div text-center">
    <button className='btn btn-primary w-75' style={{textTransform:'capitalize'}}> {item?.name}</button>
    <img className="card-img-to object-fit-fill" 
  src={`/uploads/services/${item.image}`} 
  alt="Card cap"

  
  />

       </div>

      
       </div>         
                </>
            )
            )}
</div>




<div className="main-check text-center" style={{height:'100px',width:'100%'}}>
    <div className="pic-pic">
{/* <img src='../background.png' style={{width:'100%',height:'100px'}}/> */}
<div className="text-pic">wellcome to unique software house</div>
    </div>
    
</div>


<div className="card-div">

{feature && feature.map((item,i)=>(
  <>

  <ul className=' col-10'>
    <li className='mt-2 text-start line-h-0 bg-light'>
      <NavLink to={`/single/feature/${item._id}`}>

      {
       i%2===0?<div className='text-danger'>({i+1}).<ArrowForwardIcon style={{color:'purple', width:'50px'}}/>{item.list}</div> :
       <div className='text-dark'>({i+1}).<ArrowForwardIcon style={{color:'purple', width:'50px'}}
       onClick={()=>postData(item._id)}
       />{item.list}</div>} 
       </NavLink>
   
    </li>
  </ul>
  
  </>
))}
</div>
<h3 className='main-feature'>{service.name}</h3><br/>
<Details/>
<h2 className='main-feature'>details</h2>
<h6 className='credit card-div'style={{background:'none'}}>{service.details}</h6>
<h2 className='main-feature'>credentials</h2>
<h6 className='credit card-div'>{service.credit}</h6>
{/* <button className='btn btn-primary'><Link to={`/admin/service/${service._id}`} style={{color:'white'}}>update service</Link> </button> */}
        </div>
                </div>
            </div>
    
    
    </>
            
  )
}
export default CombineServices