import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { getSingleFinalAction } from '../redux/action/finalAction'

const SingleFinalService = () => {
  const {id}=useParams()
    const {final}=useSelector(state=>state.getSingleFinalReducer)
    console.log(final);
    const  dispatch=useDispatch()
    useEffect(() => {
dispatch(getSingleFinalAction(id))
    }, [dispatch,id])
    
  return (
    <>
    <div className="container">
      <div className="row">
      <img className="card-img-top object-fit-cover mx-auto mt-2"
           src={`/uploads/pointofsale/${final.image}`} 
           alt={final.name}
        
           />
      </div>
    </div>
    <div className="container">
        <div className="row">
            <div className="title text-center m-3"><button className='btn btn-primary'>{final.title}</button> </div><br/>
            <div className="title m-3">{final.description}</div>
            {/* <button className='btn btn-success'> */}
                {/* <Link to={`/update/service/final/${final._id}`} style={{color:'black'}}>update</Link> </button> */}
        </div>
    </div>
    </>
  )
}

export default SingleFinalService