import { TextareaAutosize } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateDetailsAction } from '../redux/action/deatilsAction'
import { useParams } from 'react-router-dom'

const UpdateDetails = () => {
    const dispatch=useDispatch()
    const {id}=useParams()
    const {details}=useSelector(state=>state.getSingleDetailsReducer)
   console.log(details);
   
    const [title, setTitle] = useState(details.title)
    const [detail, setDetail] = useState(details.details)
    const postData=async()=>{
        dispatch(updateDetailsAction({id,title,detail}))
    }
  return (
   <>
   <div className="container">
    <div className="row d-flex flex-column">
    <label htmlFor="title">Title:</label>
<input type="text" className='w-75' placeholder='title' value={title}  onChange={(e)=>setTitle(e.target.value)}/><br/>

<label htmlFor="Details">Details:</label>
   <TextareaAutosize placeholder='details' className='w-75' value={detail}  onChange={(e)=>setDetail(e.target.value)}/><br/>

   <button className='btn btn-success w-25' onClick={postData}>update</button>
   
    </div>
    </div>
   </>
  )
}

export default UpdateDetails