import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import axios from 'axios'
import { getsinglePictureAction } from '../redux/action/pictureAction'
import { useDispatch, useSelector } from 'react-redux'

const PosSingle = () => {
  const {picture}=useSelector(state=>state.getSinglePictureReducer)
  console.log(picture);
  const dispatch=useDispatch()
    const params=useParams()
    let {id}=params

    useEffect(() => {
    dispatch(getsinglePictureAction(id))
    }, [dispatch,id])
    

  
  return (
   <>
<div className="container-fluid">
    <div className="row text-center">
    <img src={`/uploads/posurdu/${picture.image}`}

        
          />
   


   </div>
</div>
</>
  )
}

export default PosSingle