import axios from "axios"
import { GET_USERIP_FAIL, GET_USERIP_REQUEST, GET_USERIP_SUCCESS } from "../constant/userIp"

export const getUserAction = () => async(dispatch)=>{
    dispatch({type:GET_USERIP_REQUEST})
    try {
        // const {data}=await axios.get('http://find-ip.openjavascript.info/')
        const {data}=await axios.get('http://ip-api.com/json/?fields=61439')
        console.log(data,'ip');
    dispatch({type:GET_USERIP_SUCCESS,
        payload:data.iso.country.isoCode})
    } catch (error) {
        dispatch({type:GET_USERIP_FAIL,
            payload: error.response
        })}}