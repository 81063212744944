import {createStore,combineReducers,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import { getBannerReducer, postBannerReducer } from './components/redux/reducer/bannerReducer';
import { getFeatureReducer, getServiceReducer,getSingleFeatureReducer,postServiceReducer } from './components/redux/reducer/serviceReducer';
import { combineService, combineService2nd, getFinalImage, getlastservice, } from './components/redux/reducer/combineServiceReducer';
import { getWellcomeReducer, postWellcomeReducer, updateWellcomeReducer } from './components/redux/reducer/wellcomeReducer';
import { getSingleTestmonialReducer, getTestmonialReducer } from './components/redux/reducer/testmonial';
import { loadUserReducer, logoutUserReducer, postLoginReducer } from './components/redux/reducer/userReducer';
import { getDetailsReducer, getSingleDetailsReducer } from './components/redux/reducer/detailsReducers';
import { getSingleFinalReducer, postFinalReducer } from './components/redux/reducer/finalReducer';
import { getPictureReducer, getSinglePictureReducer } from './components/redux/reducer/pictureReducer';
import { getuserIpReducer } from './components/redux/reducer/userIpReducers';


const loadUser=localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')):null

const reducer=combineReducers({
    postBannerReducer:postBannerReducer,
    getBannerReducer:getBannerReducer,
    getServiceReducer:getServiceReducer,
    combineService:combineService,
    postServiceReducer,postServiceReducer,
    getSingleFeatureReducer:getSingleFeatureReducer,
    getlastservice:getlastservice,
    getWellcomeReducer:getWellcomeReducer,
    updateWellcomeReducer:updateWellcomeReducer,
    getTestmonialReducer:getTestmonialReducer,
    postLoginReducer:postLoginReducer,
    loadUserReducer:loadUserReducer,
    logoutUserReducer:logoutUserReducer,
    combineService2nd:combineService2nd,
    postWellcomeReducer:postWellcomeReducer,
    getFeatureReducer:getFeatureReducer,
    getDetailsReducer:getDetailsReducer,
    postFinalReducer:postFinalReducer,
    getSingleTestmonialReducer:getSingleTestmonialReducer,
    getSingleDetailsReducer:getSingleDetailsReducer,
    getSingleFinalReducer:getSingleFinalReducer,
    getPictureReducer:getPictureReducer,
    getSinglePictureReducer:getSinglePictureReducer,
    getuserIpReducer:getuserIpReducer,
    getFinalImage:getFinalImage,

    })    
    let initialState={
       userLogin:{userInfo:loadUser}
    };
    
    const middleware=[thunk];
const store=createStore(

    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)
export default store