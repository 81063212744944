export const GET_TESTMONIAL_REQUEST = "GET_TESTMONIAL_REQUEST";
export const GET_TESTMONIAL_SUCCESS = "GET_TESTMONIAL_SUCCESS";
export const GET_TESTMONIAL_FAIL = "GET_TESTMONIAL_FAIL";

export const GET_SINGLE_TESTMONIAL_SUCCESS = "GET_TESTMONIAL_SUCCESS";
export const GET_SINGLE_TESTMONIAL_REQUEST = "GET_TESTMONIAL_REQUEST";
export const GET_SINGLE_TESTMONIAL_FAIL = "GET_TESTMONIAL_FAIL";

export const UPDATE_TESTMONIAL_REQUEST = "UPDATE_TESTMONIAL_REQUEST";
export const UPDATE_TESTMONIAL_SUCCESS = "UPDATE_TESTMONIAL_SUCCESS";
export const UPDATE_TESTMONIAL_FAIL = "UPDATE_TESTMONIAL_FAIL";