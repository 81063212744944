import axios from "axios";
import { GET_ALL_USERS_FAIL, GET_ALL_USERS_REQUEST, GET_ALL_USERS_SUCCESS, 
    GET_SINGLE_USER_FAIL, GET_SINGLE_USER_REQUEST, GET_SINGLE_USER_SUCCESS, 
    GET_USER_DETAILS_FAIL, 
    GET_USER_DETAILS_REQUEST, 
    GET_USER_DETAILS_SUCCESS, 
    UPDATE_USER_DETAILS_FAIL, 
    UPDATE_USER_DETAILS_REQUEST, 
    UPDATE_USER_DETAILS_SUCCESS, 
    GET_USER_FAIL, 
    GET_USER_REQUEST, 
    GET_USER_SUCCESS, 
    LOGIN_USER_FAIL, 
    LOGIN_USER_REQUEST, 
    LOGIN_USER_SUCCESS, 
    POST_USER_FAIL, POST_USER_REQUEST, POST_USER_SUCCESS, LOAD_USER_SUCCESS, LOAD_USER_FAIL, LOAD_USER_REQUEST, PASSWORD_RESET_EMAIL_FAIL, PASSWORD_RESET_EMAIL_SUCCESS, PASSWORD_RESET_EMAIL_REQUEST, 
    GET_ALL_USER_REQUEST,
    GET_ALL_USER_SUCCESS,
    GET_ALL_USER_FAIL} from "../constant/userConstant";


export const postUserAction = (postUser) => async (dispatch) => {
    console.log(postUser,'post user action');
      try {
        dispatch({ type: POST_USER_REQUEST });
    
        const { data } = await axios.post('http://localhost:4000/register',(postUser))
        dispatch({
          type: POST_USER_SUCCESS,
          payload: data.user
        });
      } catch (error) {
        dispatch({
          type:  POST_USER_FAIL,
          payload: error.response
        });
      }}
export const loginUserAction = (loginUser) => async (dispatch) => {
    console.log(loginUser,'post student action');
      try {
        dispatch({ type: LOGIN_USER_REQUEST });
        const config = { headers: { "Content-Type": "application/json" } };
  
      const  {data}  = await axios.post(`http://localhost:4000/login`, loginUser, config);  
console.log(data,'user login data');


      dispatch({ type: LOGIN_USER_SUCCESS,payload:data
        
      });
  //  console.log(data.user,'login data user');
      localStorage.setItem("user", JSON.stringify(data));
    
       
      } catch (error) {
        dispatch({
          type:  LOGIN_USER_FAIL,
          payload: error.response
        });
      }
    };
    // get users actions
export const getAllUserAction = () => async (dispatch) => {
    
      try {
        dispatch({ type: GET_ALL_USER_REQUEST });
    
        const { data } = await axios.get(`http://localhost:4000/get/users`)
        console.log(data,'get all ausers');
        dispatch({
          type: GET_ALL_USER_SUCCESS,
          payload: data.users
        });
      } catch (error) {
        dispatch({
          type:  GET_ALL_USER_FAIL,
          payload: error.response
        });
      }
    };
export const getUserAction = (id) => async (dispatch) => {
    
      try {
        dispatch({ type: GET_USER_REQUEST });
    
        const { data } = await axios.get(`http://localhost:4000/get/user/${id}`)
        console.log(data,'get all ausers');
        dispatch({
          type: GET_USER_SUCCESS,
          payload: data.users
        });
      } catch (error) {
        dispatch({
          type:  GET_USER_FAIL,
          payload: error.response
        });
      }
    };
    export const getUserDeatilsAction = (id) => async (dispatch) => {
    
      try {
        dispatch({ type: GET_USER_DETAILS_REQUEST });
    
        const { data } = await axios.get(`http://localhost:4000/get/user/${id}`)
        console.log(data,'get user details');
        dispatch({
          type: GET_USER_DETAILS_SUCCESS,
          payload: data.user
        });
      } catch (error) {
        dispatch({
          type:  GET_USER_DETAILS_FAIL,
          payload: error.response
        });
      }
    };
    export const updateUserDeatilsAction = (userUpdateData) => async (dispatch) => {
const {data,fileName,feildValue}=userUpdateData
const {name,fatherName,email,password,phoneNumber,phoneNumber2,whatsapp,facebook,youtube,insta,website,google,city,
  address,shopName,gender,age,professionName,dob,nic,maritalStatus,religion,bloodGroup,experience,state
  ,country,district,id,}=data
console.log(userUpdateData,'user data');
    
      try {
        dispatch({ type: UPDATE_USER_DETAILS_REQUEST });
    
        const { data } = await axios.put(`http://localhost:4000/update/users/${id}`,{
          name,fatherName,email,password,phoneNumber,phoneNumber2,whatsapp,facebook,youtube,insta,website,google,city,
          address,shopName,gender,age,professionName,dob,nic,maritalStatus,religion,bloodGroup,experience,state
          ,country,district,fileName,feildValue

        })
        console.log(data,'UPDATE user details');
        dispatch({
          type: UPDATE_USER_DETAILS_SUCCESS,
          payload: data.user
        });
      } catch (error) {
        dispatch({
          type:  UPDATE_USER_DETAILS_FAIL,
          payload: error.response
        });
      }
    };

    export const loadUser = () => async (dispatch,getState) => {
 
      try {
        dispatch({ type: LOAD_USER_REQUEST });
        // const{user:{user}}=getState();
        const userFromStorage=localStorage?.getItem("user")
        ?JSON.parse(localStorage?.getItem("user")):null;
  
        // console.log(userFromStorage.user._id,'user data 1S');
        const config={
          headers:{
            // 'Content-Type':'application.json',
            Authorization:`Bearer ${userFromStorage.token}`
          }
        }
        
        // console.log(config,'config');
    
        const {data} = await axios.post(`http://localhost:4000/me`,{id:userFromStorage.user._id},config);
    console.log(data,'user data user data');
  
        dispatch({ type: LOAD_USER_SUCCESS, payload: data.user });
      } catch (error) {
        dispatch({ type: LOAD_USER_FAIL, payload:error });
      }
    };

    // email send for password recovery
    export const sendEmail = (email) => async (dispatch,getState) => {
 console.log(email,'email');
      try {
        dispatch({ type: PASSWORD_RESET_EMAIL_REQUEST});
         
        // console.log(config,'config');
    
        const {data} = await axios.post(`http://localhost:4000/sendpasswordlink`,{email:email});
    console.log(data,'user data user data');
  
        dispatch({ type:PASSWORD_RESET_EMAIL_SUCCESS, payload: data.user });
      } catch (error) {
        dispatch({ type: PASSWORD_RESET_EMAIL_FAIL, payload:error });
      }
    };
