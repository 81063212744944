import { TextareaAutosize } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateSingleFinalAction } from '../redux/action/finalAction'
import { useParams } from 'react-router-dom'

const UpdateFinalService = () => {
  const {id}=useParams()
  const {final}=useSelector(state=>state.getSingleFinalReducer)
  console.log(final);
    const [description, setDescription] = useState(final.description)
    const [title, setTitle] = useState(final.title)
    const dispatch=useDispatch()
    const postData=()=>{
      dispatch(updateSingleFinalAction({id,title,description}))
    }
  //  useEffect(() => {
  //   dispatch(updateSingleFinalAction(id))
  //    }
  //  , [dispatch,id])
   

    
  return (
<>
<div className="container">
  <div className="row">
    <input type='text' className='m-3' value={title} onChange={(e)=>setTitle(e.target.value)}/>
  <TextareaAutosize type='text' className='m-3' value={description} onChange={(e)=>setDescription(e.target.value)}/>
  
  <button className='btn btn-success' onClick={postData}>update</button> </div>
</div>


</>
  )
}

export default UpdateFinalService