export const POST_SUB_PROFESSION_REQUEST="POST_SUB_PROFESSION_REQUEST"
export const POST_SUB_PROFESSION_SUCCESS="POST_SUB_PROFESSION_SUCCESS"
export const POST_SUB_PROFESSION_FAIL="POST_SUB_PROFESSION_FAIL"

export const GET_SUB_PROFESSION_REQUEST="GET_SUB_PROFESSION_REQUEST"
export const GET_SUB_PROFESSION_SUCCESS="GET_SUB_PROFESSION_SUCCESS"
export const GET_SUB_PROFESSION_FAIL="GET_SUB_PROFESSION_FAIL"

export const GET_ALL_SUB_PROFESSION_REQUEST="GET_ALL_SUB_PROFESSION_REQUEST"
export const GET_ALL_SUB_PROFESSION_SUCCESS="GET_ALL_SUB_PROFESSION_SUCCESS"
export const GET_ALL_SUB_PROFESSION_FAIL="GET_ALL_SUB_PROFESSION_FAIL"