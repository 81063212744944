import React from 'react'
import './software.css'
import { useSelector } from 'react-redux'
const SoftwareServices = () => {
    const {service}=useSelector(state=>state.combineService)
  return (
    <>
    <div className="container-fluid">
        
<div className="s-services d-flex flex-row">
<div className="row " >
{service.map((item)=>
    (
        <>
        <div className="card" style={{width:'11rem', height:'10rem',}}>
  <img className="card-img-top object-fit-cover  mx-auto" src={`/uploads/services/${item.image}`} alt="Card image cap"style={{borderRadius:'100%',width:'6rem', height:'6rem'}}/>
  <div className="card-body">
   <div className="card-title s-s-name" 
   style={{width:'11rem'}}
   >{service.name}</div>
  </div>
</div>
        </>
    )
    )}
    
</div>
        </div>
    </div>
  </>
  )
}

export default SoftwareServices