import { BANNER_UPLOAD_FAIL, BANNER_UPLOAD_REQUEST, BANNER_UPLOAD_SUCCESS, GET_BANNER_FAIL, GET_BANNER_REQUEST, GET_BANNER_SUCCESS } from '../constant/BannerConstant';
export const postBannerReducer = (state = { banner: [] }, action) => {
    switch (action.type) {
        case BANNER_UPLOAD_REQUEST:
         
            return {
              loading: true,
              banner: [],
            }

case BANNER_UPLOAD_SUCCESS:
    
    return{
        loading:false,
        banner:action.payload,
    }
    case BANNER_UPLOAD_FAIL:
        console.log(action.payload);
        return{
            loading:false,
            banner:action.payload
        }


            default:
                return state;}}
                export const getBannerReducer = (state = { banner: [] }, action) => {
                    switch (action.type) {
                        case GET_BANNER_REQUEST:                         
                            return {
                              loading: true,
                              banner: [],
                            }
                        case GET_BANNER_SUCCESS:
                            console.log(action.payload,'action paylaod');
                            return {
                                // ...state,
                              loading: false,
                              banner:action.payload,
                              
                            }
                            
                        case GET_BANNER_FAIL:
                           
                         
                            return {
                              loading: false,
                              banner: action.payload,
                            }
                        
                            default:
                                return state
                        }

                }