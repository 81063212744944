export const GET_WELLCOME_REQUEST = "GET_WELLCOME_REQUEST";
export const GET_WELLCOME_SUCCESS = "GET_WELLCOME_SUCCESS";
export const GET_WELLCOME_FAIL = "GET_WELLCOME_FAIL";

export const WELLCOME_POST_REQUEST = "WELLCOME_POST_REQUEST";
export const WELLCOME_POST_SUCCESS = "WELLCOME_POST_SUCCESS";
export const WELLCOME_POST_FAIL = "WELLCOME_POST_FAIL";

export const UPDATE_WELLCOME_REQUEST = "UPDATE_WELLCOME_REQUEST";
export const UPDATE_WELLCOME_SUCCESS = "UPDATE_WELLCOME_SUCCESS";
export const UPDATE_WELLCOME_FAIL = "UPDATE_WELLCOME_FAIL";