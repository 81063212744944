import axios from "axios";
import { GET_ALL_SUB_PROFESSION_FAIL, GET_ALL_SUB_PROFESSION_REQUEST, GET_ALL_SUB_PROFESSION_SUCCESS, GET_SUB_PROFESSION_FAIL, GET_SUB_PROFESSION_REQUEST, GET_SUB_PROFESSION_SUCCESS, POST_SUB_PROFESSION_FAIL, POST_SUB_PROFESSION_REQUEST, POST_SUB_PROFESSION_SUCCESS } from "../constant/subProfessionConstant";

export const postSubProfessionAction = (postProfession) => async (dispatch) => {
    console.log(postProfession,'post SUB_PROFESSION action');
      try {
        dispatch({ type: POST_SUB_PROFESSION_REQUEST });
    
        const { data } = await axios.post('http://localhost:4000/admin/add/subProfession',postProfession);
    console.log(data.subProfession,'get all subProfession actions');
        dispatch({
          type: POST_SUB_PROFESSION_SUCCESS,
          payload: data.subProfession
        });
      } catch (error) {
        dispatch({
          type:  POST_SUB_PROFESSION_FAIL,
          payload: error.response
        });
      }
    };

export const getSubProfessionAction = (id) => async (dispatch) => {
  console.log(id,'get sub profession');
  let abc=id.split(",")
    const _id=abc[1]
      try {
        dispatch({ type: GET_SUB_PROFESSION_REQUEST });
    
        const { data } = await axios.post('http://localhost:4000/get/subprofession',({_id:_id}));
    console.log(data.subProfession,'get all subProfession actions');
        dispatch({
          type: GET_SUB_PROFESSION_SUCCESS,
          payload: data.subProfession
        });
      } catch (error) {
        dispatch({
          type:  GET_SUB_PROFESSION_FAIL,
          payload: error.response
        });
      }
    };
export const getAllSubProfessionAction = () => async (dispatch) => {
      try {
        dispatch({ type: GET_ALL_SUB_PROFESSION_REQUEST });
    
        const { data } = await axios.get('http://localhost:4000/get/allsubprofession')
    console.log(data,'get all subProfession actions');
        dispatch({
          type: GET_ALL_SUB_PROFESSION_SUCCESS,
          payload: data.subProfession
        });
      } catch (error) {
        dispatch({
          type:  GET_ALL_SUB_PROFESSION_FAIL,
          payload: error.response
        });
      }
    };