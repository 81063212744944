import { WELLCOME_POST_FAIL, WELLCOME_POST_REQUEST, WELLCOME_POST_SUCCESS,
  GET_WELLCOME_FAIL, GET_WELLCOME_REQUEST, GET_WELLCOME_SUCCESS,
  UPDATE_WELLCOME_FAIL, UPDATE_WELLCOME_REQUEST, UPDATE_WELLCOME_SUCCESS,
  DELETE_WELLCOME_FAIL, DELETE_WELLCOME_REQUEST, DELETE_WELLCOME_SUCCESS
} from '../constant/wellcomeConstant';

export const postWellcomeReducer = (state = { wellcome: [] }, action) => {
  switch (action.type) {
      case WELLCOME_POST_REQUEST:
       
          return {
            loading: true,
            wellcome: [],
          }

case WELLCOME_POST_SUCCESS:
  
  return{
      loading:false,
      wellcome:action.payload,
  }
  case WELLCOME_POST_FAIL:
      console.log(action.payload);
      return{
          loading:false,
          wellcome:action.payload
      }


          default:
              return state;}}




export const getWellcomeReducer = (state = { wellcome: {} }, action) => {
    switch (action.type) {
        case GET_WELLCOME_REQUEST:
         
            return {
              loading: true,
              wellcome: {},
            }
        case GET_WELLCOME_SUCCESS:
         
            return {
              loading: false,
              wellcome:action.payload,
            }
        case GET_WELLCOME_FAIL:
         
            return {
              loading: false,
              wellcome: action.payload,
            }
        
            default:
                return state
        }

}
export const updateWellcomeReducer = (state = { updatewellcome: [] }, action) => {
  switch (action.type) {
      case UPDATE_WELLCOME_REQUEST:
       
          return {
            loading: true,
            updatewellcome: [],
          }
      case UPDATE_WELLCOME_SUCCESS:
       
          return {
            loading: false,
            updatewellcome:action.payload,
          }
      case UPDATE_WELLCOME_FAIL:
       
          return {
            loading: false,
            wellcome: action.payload,
          }
      
          default:
              return state
      }

}