import React from 'react'
import { useParams } from 'react-router-dom'
import data from './branchTeam.json'
const BranchTeam = () => {
    const {name}=useParams()
    console.log(name,'team member');
  return (
    <>
    {data?.filter((item)=>item.header===name)
    .map((item)=>(<>
    {item.designation}<br/>
    </>))
    
    }
    </>
  )
}

export default BranchTeam