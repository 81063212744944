import { GETCOMBINESERVICE_SERVICE_FAIL, GETCOMBINESERVICE_SERVICE_REQUEST, GETCOMBINESERVICE_SERVICE_SUCCESS,
    GETCOM_SERVICE_FAIL, GETCOM_SERVICE_REQUEST, GETCOM_SERVICE_SUCCESS,
    GET_FINALIMAGE_REQUEST,GET_FINALIMAGE_SUCCESS,GET_FINALIMAGE_FAIL,
    FINALSERVICE_FAIL, FINALSERVICE_REQUEST, FINALSERVICE_SUCCESS,
} from "../constant/serviceConstant";
export const combineService = (state = { service: {} }, action) => {
    switch (action.type) {
        case GETCOMBINESERVICE_SERVICE_REQUEST:
         
            return {
              loading: true,
              service: {},
            }

case GETCOMBINESERVICE_SERVICE_SUCCESS:
    console.log(action.payload,'action paylaod');
    return{
        loading:false,
    service:action.payload,
    }
    case GETCOMBINESERVICE_SERVICE_FAIL:
        // console.log(action.payload);
        return{
            loading:false,
           service:action.payload
        }


            default:
                return state;}}

export const getlastservice = (state = { lastservice: {} }, action) => {
    switch (action.type) {
        case FINALSERVICE_REQUEST:
         
            return {
              loading: true,
              lastservice: {},
            }

case FINALSERVICE_SUCCESS:
    // console.log(action.payload,'action paylaod');
    return{
        loading:false,
  lastservice:action.payload,
    }
    case FINALSERVICE_FAIL:
        // console.log(action.payload);

        return{
            loading:false,
          lastservice:action.payload
        }
        
        default:
            return state;
    }}

export const combineService2nd = (state = { service2nd: [] }, action) => {
    switch (action.type) {
        case GETCOM_SERVICE_REQUEST:
         
            return {
              loading: true,
              service2nd: [],
            }

case GETCOM_SERVICE_SUCCESS:
    // console.log(action.payload,'action paylaod');
    return{
        loading:false,
    service:action.payload,
    }
    case GETCOM_SERVICE_FAIL:
        // console.log(action.payload);
        return{
            loading:false,
           service2nd:action.payload
        }


            default:
                return state;}}

export const getFinalImage = (state = { imageservice: {} }, action) => {
    switch (action.type) {
        case GET_FINALIMAGE_REQUEST:
         
            return {
              loading: true,
              imageservice:{},
            }

case GET_FINALIMAGE_SUCCESS:
    console.log(action.payload,'action paylaod');
    return{
        loading:false,
        imageservice:action.payload,
    }
    case GET_FINALIMAGE_FAIL:
        // console.log(action.payload);
        return{
            loading:false,
            imageservice:action.payload.error
        }


            default:
                return state;}}

