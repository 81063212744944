import {LOGIN_REQUEST,LOGIN_SUCCESS,LOGIN_FAIL, LOAD_USER_FAIL, LOAD_USER_SUCCESS, LOAD_USER_REQUEST, LOGOUT_SUCCESS} from '../constant/userConstant'
export const postLoginReducer = (state = { userInfo: {} }, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
         
            return {
              loading: true,
              userInfo: {},
            }
        case LOGIN_SUCCESS:
         
            return {
              loading: false,
              userInfo:action.payload,
            }
        case LOGIN_FAIL:
         
            return {
              loading: false,
              userInfo: action.payload,
            }
         
            default:
                return state
        }

}
export const loadUserReducer = (state = { userInfo: {} }, action) => {
    switch (action.type) {
        case LOAD_USER_REQUEST:
         
            return {
              loading: true,
              userInfo: {},
            }
        case LOAD_USER_SUCCESS:
         
            return {
              loading: false,
              userInfo:action.payload,
            }
        case LOAD_USER_FAIL:
         
            return {
              loading: false,
              userInfo: action.payload,
            }
         
            default:
                return state
        }

}
export const logoutUserReducer = (state = { user: {} }, action) => {
  switch (action.type) {

    case LOGOUT_SUCCESS:
                         
                            return {
                              loading: false,
                              user:action.payload,
                            }
                            default:
                              return state
                      }

  }
 