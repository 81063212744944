import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useParams } from 'react-router-dom'
import { getSingleFeatureAction, updateFeatureAction } from '../redux/action/serviceAction'
import UpdateFeature from './UpdateFeature'
import { TextareaAutosize } from '@mui/material'

const GetSingleFeature = () => {
  
  const {id}=useParams()
    const {singleFeature}=useSelector(state=>state.getSingleFeatureReducer)   
    console.log(singleFeature,'single feature');    
const dispatch=useDispatch()

useEffect(() => {
dispatch(getSingleFeatureAction(id))
}, [dispatch,id])


  return (
  <>
  <div className="container">
    <div className="row">
      {singleFeature.list}
      <Link to={`/update/feature/${singleFeature._id}`}> update</Link>
    {/* <TextareaAutosize type='text' value={list} onChange={(e)=>setList(e.target.value)}/><br/>
  <button className='btn btn-success' onClick={postData} >update</button> */}
    </div>
  </div>

  {/* <UpdateFeature/> */}
 {/* {userInfo.role==='admin'?<button className='btn btn-warning ms-3' 
   onClick={()=>(singleFeature._id)}><NavLink to={`/update/feature/${singleFeature._id}`}>update</NavLink></button>:null}
    */}
  </>
  )
}

export default GetSingleFeature