
import {  GET_PICTURE_FAIL, GET_PICTURE_REQUEST, GET_PICTURE_SUCCESS,
    GET_SINGLE_PICTURE_FAIL, GET_SINGLE_PICTURE_REQUEST, GET_SINGLE_PICTURE_SUCCESS} from '../constant/pictureConstant'
export const getPictureReducer = (state = { picture: [] }, action) => {
    switch (action.type) {
        case GET_PICTURE_REQUEST:
         
            return {
              loading: true,
              picture: [],
            }
        case GET_PICTURE_SUCCESS:
         
            return {
              loading: false,
              picture:action.payload,
            }
        case GET_PICTURE_FAIL:
         
            return {
              loading: false,
              picture: action.payload,
            }
        
            default:
                return state
        }

}
export const getSinglePictureReducer = (state = { picture: {} }, action) => {
    switch (action.type) {
        case GET_SINGLE_PICTURE_REQUEST:
         
            return {
              loading: true,
              picture: {},
            }
        case GET_SINGLE_PICTURE_SUCCESS:
         
            return {
              loading: false,
              picture:action.payload,
            }
        case GET_SINGLE_PICTURE_FAIL:
         
            return {
              loading: false,
              picture: action.payload,
            }
        
            default:
                return state
        }

}