export const POST_STATE_REQUEST="POST_STATE_REQUEST"
export const POST_STATE_SUCCESS="POST_STATE_SUCCESS"
export const POST_STATE_FAIL="POST_STATE_FAIL"

export const GET_STATE_REQUEST="GET_STATE_REQUEST"
export const GET_STATE_SUCCESS="GET_STATE_SUCCESS"
export const GET_STATE_FAIL="GET_STATE_FAIL"

export const GET_ALL_STATE_REQUEST="GET_ALL_STATE_REQUEST"
export const GET_ALL_STATE_SUCCESS="GET_ALL_STATE_SUCCESS"
export const GET_ALL_STATE_FAIL="GET_ALL_STATE_FAIL"