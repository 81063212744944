import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getFinalAction } from '../redux/action/finalAction';
import { Link, useParams } from 'react-router-dom';

const FinalServices = () => {
  const {final}=useSelector(state=>state.postFinalReducer)
  const {imageservice}=useSelector(state=>state.getFinalImage)
  console.log(imageservice);
  const {slug}=useParams()
  const dispatch=useDispatch()
  useEffect(() => {
dispatch(getFinalAction(slug))
  },[dispatch])
  const postData={

  }
  return (
    <>
    <div className="container">
      <div className="row">
      <img className="card-img-top object-fit-cover mx-auto mt-2"
           src={`/uploads/services/${imageservice.image}`} 
           alt={imageservice.name} style={{}}
         
           />
      </div>
    </div>
    <div className="container-fluid">
  <div className="row d-flex flex-row justify-content-between">
  {final && final.map((item,i)=>(
    <>

    {
  <div className="col-lg-4 col-sm-5 col-md-5 justify-content-between">

<div className="card bg-dark text-light mx-auto" style={{width:'300px',height:'500px'}}>
  <img className="card-img-top w-50 text-center mx-auto mt-3" src={`/uploads/pointofsale/${item.image}`} alt={item.name}/>
  <div className="card-body">
<div className='text-danger'><h5 className="card-title text-capitalize">({i+1})..{item.title.substring(0,20)}...</h5></div>
    <div className="card-text">
   {item.description.substring(0,300)}...
   
   
   <button className='btn btn-success'><Link to ={`/service/single/${item._id}`} style={{color:'black'}}>Read More</Link> </button></div>

  </div>
</div>
  </div>
    }


    </>
  ))}
    </div>
</div>
    
    </>
  )
}

export default FinalServices