import logo from './logo.svg';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './components/wellcome/buttonupdate.css'
import Navbar from './components/navbar/Navbar';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Routes, Route, NavLink,} from "react-router-dom";
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Software from './components/pages/software/Software'
import Graphic from './components/pages/graphicDesign/Graphic'
import VideoEditing from './components/pages/videoEditing/VideoEditing'
import Seo from './components/pages/seo/Seo'
import Webdevelopment from './components/pages/webdevelopment/Webdevelopment.jsx'
import WebDesigning from './components/pages/webDesign/WebDesigning'
import Home from './components/pages/homePage/Home';
import OurServices from './components/services/OurServices';
import Contact from './components/contact/Contact'
import About from './components/about/About';
import Portfolio from './components/portfolio/Portfolio';
import NewService from './components/services/NewService';
import Services from './components/services/Services';
import CombineServices from './components/services/CombineServices';
import FinalServices from './components/services/FinalServices';
import UpdateFinalServices from './components/services/UpdateFinalService';
import UpdateWellcome from './components/wellcome/UpdateWellcome';
import UpdateService from './components/services/UpdateService';
import store from './store';
import { getWellcomeAction } from './components/redux/action/wellcomeAction';
import { getSingleFeatureAction, getserviceAction } from './components/redux/action/serviceAction';
import UpdateTestmonial from './components/testimonial/UpdateTestmonial';
import Login from './components/pages/user/Login';
import { loadUser } from './components/redux/action/usersAction.jsx';
import Profile from './components/div/profile/Profile';
import Logout from './components/pages/user/Logout';
import ProtectedRoutes from './components/pages/privateRoutes/ProtectedRoutes';
import Dashboard from './components/Dashboard/Dashboard';
import AddBanner from './components/banner/AddBanner';
import Test from './components/test/Test';
import UpdateFeature from './components/services/UpdateFeature';
import GetSingleFeature from './components/services/GetSingleFeature';
import SingleTestimonial from './components/testimonial/SingleTestimonial';
import CombineServiceId from './components/services/CombineServiceId';
import UpdateDetails from './components/services/UpdateDetails';
import SingleFinalService from './components/services/SingleFinalService';
import UpdateFinalService from './components/services/UpdateFinalService';
import Register from './components/forms/Register';
import WhatsApp from './components/whatapp/WhatsApp';
import WeOffer from './components/weoffer/WeOffer';
import PosSingle from './components/pos/PosSingle';
import Pos from './components/pos/Pos';
import { getUserAction } from './components/redux/action/userIp.jsx';
import PricePlan from './components/price plan/PricePlan';
import { getBannerAction } from './components/redux/action/bannerAction.jsx';
import NewFooter from './components/footer/NewFooter.jsx';
import AcademyRegister from './components/forms/AcademyRegister.jsx';
import PostJob from './components/PostJOb/PostJobs.jsx';
import { useEffect } from 'react';
import BranchTeam from './components/Team/BranchTeam.jsx';
import Team from './components/Team/Team.jsx';

function App() {
  useEffect(() => {
store.dispatch(getWellcomeAction())
store.dispatch(getserviceAction())
store.dispatch(getUserAction())
store.dispatch(loadUser())
store.dispatch(getBannerAction())
window.scrollTo(0, 0); 
}, []);

  return (
  <>
 
<Router>
  {/* <Header/> */}
<WeOffer/>
  <Navbar/>
  <Routes>
<Route path="/" element={<Home/>}/>
<Route path="/new" element={<Pos/>}/>
<Route path="/post/job" element={<PostJob/>}/>
<Route path="/pos/image/:id" element={<PosSingle/>}/>
<Route path="/register" element={<Register/>}/>
<Route path="/academy/register" element={<AcademyRegister/>}/>
{/* <Route path="/" element={<ProtectedRoutes/>} > */}
  {/* update */}
<Route path="/update/wellcome/:id" element={<UpdateWellcome/>}/>
<Route path="/update/service/:slug" element={<UpdateService/>}/>
<Route path="/update/testmonial/:id" element={<UpdateTestmonial/>}/>
<Route path="/services/combine/:slug" element={<FinalServices/>}/>
<Route path="/service/single/:id" element={<SingleFinalService/>}/>
<Route path="/update/service/final/:id" element={<UpdateFinalService/>}/>
{/* <Route path="/update/services/combine/:slug" element={<UpdateFinalServices/>}/> */}
{/* delete */}

{/* post routes */}

{/* </Route> */}
<Route path="/dashboard" element={<Dashboard/>}/>
<Route path="/add/banner" element={<AddBanner/>}/>
<Route path="/contactus" element={ <Contact/>}/>
<Route path="/about" element={<NewService/>}/>
<Route path="/pricing" element={<PricePlan/>}/>
<Route path="/portfolio" element={<Software/>}/>
<Route path="/services" element={<Services/>}/>
<Route path="/admin/add/service/" element={<NewService/>}/>
<Route path="/service/:slug" element={<CombineServices/>}/>
<Route path="/admin/service/:id" element={<UpdateService/>}/>
<Route path="/update/service/:slug/:id" element={<UpdateDetails/>}/>
<Route path="/service/:slug/:id" element={<CombineServiceId/>}/>
<Route path="/update/feature/:id" element={<UpdateFeature/>}/>
<Route path="/single/feature/:id" element={<GetSingleFeature/>}/>
<Route path="/testimonial/:id" element={<SingleTestimonial/>}/>
<Route path="/login" element={<Login/>}/>
<Route path="/logout" element={<Logout/>}/>
<Route path="/profile" element={<Profile/>}/>
<Route path="/team" element={<Team/>}/>
<Route path="/team/:name" element={<BranchTeam/>}/>
  </Routes>
  <NewFooter/>
  <WhatsApp/>
</Router>
  
  </>
  );
}

export default App;
