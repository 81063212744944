import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loadUser } from '../../redux/action/usersAction'
import Logout from '../../pages/user/Logout'
import { Link } from 'react-router-dom'

const Profile = () => {
    const {userInfo}=useSelector(state=>state.loadUserReducer)
   
    const dispatch=useDispatch()
    useEffect(() => {
   dispatch(loadUser())
    }, [dispatch])
    
  return (
 <>
<div className="container">
  <div className="row">
<button className='btn btn-primary'>{userInfo&&userInfo.name}</button><br/>
<img src={`/uploads/userprofile/${userInfo.image}`} alt='SP'
 style={{width:'440px',height:'440px',borderRadius:'50px',alignItems:'center',alignContent:'center',marginTop:'15px'}}
 />
 <Link to='/logout'>Logout</Link>
 {userInfo.email}

  </div>
</div>
 </>
  )
}

export default Profile