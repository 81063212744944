import React from 'react'
import './software.css'
import BannerSoftware from './BannerSoftware'
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import SoftwareServices from './SoftwareServices';
import SoftwareCre from './SoftwareCre';
import OurServices from '../../services/OurServices';
const Software = () => {
  return (
   <>
   <div className="container-fluid">
    <div className="row">
    <OurServices/>
    <BannerSoftware/>
   <SoftwareServices/>
      <div className="screenshot bg-info" style={{height:'500px',width:'100%'}}>

      </div>

      <SoftwareCre/>
      <div className="row mt-5">
        <h1>Our Main Features</h1>
        <ul>
          <li> Stock Management</li>
          <li> Invoice & Barcode Scanner option</li>
          <li>Sale & Purchase daily, Monthly record</li>
          <li>Add Multiple users.</li>
          <li>Compatible to all types of shops superstores and other businesses</li>
          <li>Unlimited Product categories</li>
          <li>Easy to manage and superfast</li>
          <li>GST enable or disable option</li>
          <li>Online or Offline you can select 1 mod to install.</li>


        </ul>
      </div>
      <div className="row">
<h4>software credibility</h4>
An easy and simple accounting package that fully integrates with POS Desktop, offers general ledger, tax calculation and integrated payroll.It gives you track of each activity like invoices and orders along with alerts for overdue payments. With its payroll functionality you can manage your employee’s advance salaries, taxes and other benefits. By using reporting filters you get the information as you require along with an export option.Customer / Supplier account trackingBank accounts & cash on hand trackingEasy invoice customizationAccount summary and profit.

A retail software or POS system is an fundamentals part of running any successful store in today’s world. Point of Sale systems can help you to better understand your customers, your staff and your business, letting you to drive towards better profits and smarter ways of working.

For our money, the best POS system you can choose is Easy POS or POS Advance, Which have very competitive market price.A best POS is easy to understand and flexible in its design and Easy POS and POS advance are designed to understanding the customers choice and to avoid complexity.

      </div>
      
      
      </div></div>
   </>
  )
}

export default Software