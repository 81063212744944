import axios from "axios";
import { POST_COMBINE_FAIL, POST_COMBINE_REQUEST, POST_COMBINE_SUCCESS } from "../constant/combineConstant";

export const postCombineAction = (postCombine) => async (dispatch) => {
    console.log(postCombine,'post COUNTRY action');
      try {
        dispatch({ type: POST_COMBINE_REQUEST });
    
        const { data } = await axios.post('http://localhost:4000/combine/occupation',postCombine);
    console.log(data,'get all country actions');
        dispatch({
          type: POST_COMBINE_SUCCESS,
          payload: data
        });
      } catch (error) {
        dispatch({
          type:  POST_COMBINE_FAIL,
          payload: error.response
        });
      }
    };
