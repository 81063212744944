export const SERVICE_UPLOAD_REQUEST = "SERVICE_UPLOAD_REQUEST";
export const SERVICE_UPLOAD_SUCCESS = "SERVICE_UPLOAD_SUCCESS";
export const SERVICE_UPLOAD_FAIL = "SERVICE_UPLOAD_FAIL";

export const GET_SERVICE_REQUEST = "GET_SERVICE_REQUEST";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAIL = "GET_SERVICE_FAIL";

export const GET_FINALIMAGE_REQUEST = "GET_FINALIMAGE_REQUEST";
export const GET_FINALIMAGE_SUCCESS = "GET_FINALIMAGE_SUCCESS";
export const GET_FINALIMAGE_FAIL = "GET_FINALIMAGE_FAIL";

export const UPDATE_SERVICE_REQUEST = "GET_SERVICE_REQUEST";
export const UPDATE_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const UPDATE_SERVICE_FAIL = "GET_SERVICE_FAIL";

export const DELETE_SERVICE_REQUEST = "DELETE_SERVICE_REQUEST";
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL";

export const GET_FINALSERVICE_REQUEST = "GET_FINALSERVICE_REQUEST";
export const GET_FINALSERVICE_SUCCESS = "GET_FINALSERVICE_SUCCESS";
export const GET_FINALSERVICE_FAIL = "GET_FINALSERVICE_FAIL";

export const GET_FEATURE_REQUEST = "GET_FEATURE_REQUEST";
export const GET_FEATURE_SUCCESS = "GET_FEATURE_SUCCESS";
export const GET_FEATURE_FAIL = "GET_FEATURE_FAIL";

export const UPDATE_FEATURE_REQUEST = "GET_FEATURE_REQUEST";
export const UPDATE_FEATURE_SUCCESS = "GET_FEATURE_SUCCESS";
export const UPDATE_FEATURE_FAIL = "GET_FEATURE_FAIL";

export const GET_SINGLEFEATURE_REQUEST = "GET_FEATURE_REQUEST";
export const GET_SINGLEFEATURE_SUCCESS = "GET_FEATURE_SUCCESS";
export const GET_SINGLEFEATURE_FAIL = "GET_FEATURE_FAIL";

export const FINALSERVICE_REQUEST = "FINALSERVICE_REQUEST";
export const FINALSERVICE_SUCCESS = "FINALSERVICE_SUCCESS";
export const FINALSERVICE_FAIL = "FINALSERVICE_FAIL";

export const GETCOMBINESERVICE_SERVICE_REQUEST = "GETCOMBINESERVICE_SERVICE_REQUEST";
export const GETCOMBINESERVICE_SERVICE_SUCCESS = "GETCOMBINESERVICE_SERVICE_SUCCESS";
export const GETCOMBINESERVICE_SERVICE_FAIL = "GETCOMBINESERVICE_SERVICE_FAIL";

export const GETCOM_SERVICE_REQUEST = "GETCOM_SERVICE_REQUEST";
export const GETCOM_SERVICE_SUCCESS = "GETCOM_SERVICE_SUCCESS";
export const GETCOM_SERVICE_FAIL = "GETCOM_SERVICE_FAIL";