import axios from 'axios'
import { 
     GET_PICTURE_FAIL, GET_PICTURE_REQUEST, GET_PICTURE_SUCCESS,
     GET_SINGLE_PICTURE_FAIL, GET_SINGLE_PICTURE_REQUEST, GET_SINGLE_PICTURE_SUCCESS
    ,} from '../constant/pictureConstant'


export const getPictuerAction = () => async(dispatch)=>{
    dispatch({type:GET_PICTURE_REQUEST})
    try {
        const {data}=await axios.get('/picture')
        console.log(data,'picture data');
    dispatch({type:GET_PICTURE_SUCCESS,
        payload:data.picture})
    } catch (error) {
        dispatch({type:GET_PICTURE_FAIL,
            payload: error.response.data,
        })}}

export const getsinglePictureAction = (id) => async(dispatch)=>{
    dispatch({type:GET_SINGLE_PICTURE_REQUEST})
    try {
        const {data}=await axios.get(`/picture/${id}`)
        console.log(data,'picture data');
    dispatch({type:GET_SINGLE_PICTURE_SUCCESS,
        payload:data.picture})
    } catch (error) {
        dispatch({type:GET_SINGLE_PICTURE_FAIL,
            payload: error.response.data,
        })}}
