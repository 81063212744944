import React from 'react'
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LanguageIcon from '@mui/icons-material/Language';
import ApiIcon from '@mui/icons-material/Api';
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import GoogleIcon from '@mui/icons-material/Google';
import './newfooter.css'
import WhatsApp from '@mui/icons-material/WhatsApp';
const NewFooter = () => {
  return (
    <>
    <div className="newfooter">
        <div className="products-footer">
<ul>
    
    <li><a href=""><LanguageIcon/>Website Development</a></li>  
    <li><a href=""><ApiIcon/>Software Development</a></li>  
    <li><a href=""><ViewComfyAltIcon/>web Designing</a></li>  
    <li><a href=""><GroupAddIcon/>Social Marketing</a></li>  
    <li><a href=""><GraphicEqIcon/>Graphic Designing</a></li>  
</ul>
        </div>
        <div className="usefulllink">
<ul>

    
    <li><a href=""><YouTubeIcon/> YouTube</a></li>
    <li><a href=""><FacebookIcon/>FaceBook</a></li>
    <li><a href=""><WhatsApp/>WhatsApp</a></li>
    <li><a href=""><InstagramIcon/>InstaGram</a></li>
   </ul>
        </div>
        <div className="contact">
<ul>
<a href=""><FmdGoodIcon style={{color:'#CF970C'}}/> Office#. 15 Ground Floor Ghakar Plaza, behind SilkWay Plaza, Haripur,KPK, Pakistan
       </a><br/>
<a href="">    <EmailIcon style={{color:'#CF970C'}}/>
           uniquesoftwarehouses@gmail.com</a><br/>
<a href=""><PhoneIcon style={{color:'#CF970C'}}/> <a href='http://wa.me/923144763232' className='text-light' target='_blank'>
             + 92 300 930 98 19</a></a><br/>
<a href="">
<PhoneIcon style={{color:'#CF970C'}}/> + 92 314 476 32 32
</a>
</ul>
        </div>
    </div>
    
    
    </>
  )
}

export default NewFooter
