import React, { useEffect } from 'react'
import { logout } from '../../redux/action/usersAction'
import { useDispatch } from 'react-redux'

const Logout = () => {
    const dispatch=useDispatch()
    useEffect(() => {
    dispatch(logout())
    }, [dispatch])
    
  return (
   <>
   <button className='btn btn-info' onClick={()=>dispatch(logout())}>logout</button>
   
   </>
  )
}

export default Logout