import { GET_USERIP_FAIL, GET_USERIP_REQUEST, GET_USERIP_SUCCESS } from "../constant/userIp"
export const getuserIpReducer = (state = { userip: {} }, action) => {
    switch (action.type) {
        case GET_USERIP_REQUEST:
         
            return {
              loading: true,
              userip: {},
            }
        case GET_USERIP_SUCCESS:
          console.log(action.payload,'appp');
         
            return {
              loading: false,
              userip:action.payload,
            }
        case GET_USERIP_FAIL:
         
            return {
              loading: false,
              banner: action.payload,
            }
        
            default:
                return state
        }

}