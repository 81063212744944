import React from 'react'
import './navbar.css'
import {Link, NavLink, useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux'
import RegLog from './RegLog'
const Navbar = () => {
  const navigate=useNavigate()
  const handleClick=()=>{
    navigate('/post/job')
  }
  const {userInfo}=useSelector(state=>state.loadUserReducer)
  return (
    <div className="container-fluid mt-2 navbar-all">
        <div className="row">
            <div className="top-navbar">
            <div className="logo mt-2">

             <Link to='/'> 
             <img src="./logo/logo.jpg" style={{height: '60px',width: '70px'}}/>
             </Link>
            </div>
           <div className="menu">
            <ul className='ul-list'>
              
                <li><Link to='/'  className='navActive' style={{textDecoration:'none',color:'black', margin:'5px'}}>
    
                 Home </Link></li>
                <li>
                  <Link to='/team' className='navActive'style={{textDecoration:'none',color:'black'}} >
                 Our Team</Link></li>
                <li><Link to='/contactus' target='_blank' className='navActive' style={{textDecoration:'none',color:'black'}}>
             Contact</Link></li>
                <li><Link to='/register' target='_blank' className='navActive' style={{textDecoration:'none',color:'black'}}>
             register</Link></li>
                {/* <li><NavLink to='/contact' style={{textDecoration:'none'}}>Contact</NavLink></li> */}
           
            </ul>
           </div>
           <div className="userarea d-flex flex-row">
<ul className='ul-list'>
  


                
</ul>
            </div>
            <div className="jobpost">
          <button className='btn btn-warning w-100' onClick={handleClick}>Post Your Job</button>
          </div>
            </div>
         
        </div>
    </div>
  )
}

export default Navbar