import React, { useEffect, useState } from 'react'
import './form.css'
import '../TopBar/Topbar.css'
import './userdetails.css'
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { postCountryAction } from '../redux/action/countryAction'
import "./Register.css";
import {getSubProfessionAction} from '../redux/action/subProfessionAction'
import { useFormik, useField, useFormikContext } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
// import { register } from '../redux/action/userAction';
import { useAlert } from "react-alert";
import { getStateAction } from "../redux/action/stateAction";
import { getDistrictAction } from "../redux/action/districtAction";
import { getTehsilAction, postTehsilAction } from "../redux/action/tehsilAction";
import { postUserAction } from "../redux/action/userAction";
import { getAllProfessionAction, postProfessionAction } from '../redux/action/professionAction';
import { postCombineAction } from '../redux/action/combineAction';
const AcademyRegister = () => {
    const [education, setEducation] = useState([{degree:'',passingYear:'', majorSubjects:'',board:'',grade:''}])
    const [experience, setExperience] = useState([{post:'',company:'',jobDescription:'',years:''}])
    const {countries}=useSelector(state=>state.getAllCountriesReducer)
    const {profession}=useSelector(state=>state.getProfessionReducer)
    const {subProfession}=useSelector(state=>state.getSubProfessionReducer)
    // console.log(education,'education');
    // console.log(countries,'countries');
    const {states}=useSelector(state=>state.getStatesReducer)
    const {district}=useSelector(state=>state.getDistrictReducer)
    const {tehsil}=useSelector(state=>state.getTehsilReducer)
    const combineProfession=useSelector(state=>state.postCombineReducer)
    // console.log(combineProfession,'combine profession');
    const [preview, setPreview] = useState('')
    const [fileName, setFileName] = useState('')
    const [showHide, setShowHide] = useState('')
    const [country, setCountry] = useState('')
    const [valu, setValu] = useState('')
    let [professions, setProfessions] = useState(null)
    let [occupations, setOccupations] = useState(null)
    let [subOccupations, setSubOccupations] = useState(null)
    let [subProfessions, setSubProfessions] = useState(null)
    // console.log(professions,'professionName');
  const dispatch=useDispatch()
  const alert=useAlert()

  const handleState=(id)=>{
    dispatch(getStateAction(id))
  }
  useEffect(() => {
   dispatch(getAllProfessionAction())
   }, [dispatch])
  
    const {handleBlur,handleReset,handleSubmit,setFieldValue,handleChange,values,touched,errors}=useFormik({
        initialValues:{
     state:'',district:'',name:'',   fatherName:'',  shopName:'',  email:'',  fileName:'',other:'',
     password:'',  facebook:'',  youtube:'',  insta:'',  website:'',  city:'',
     address:'', describe:'',phoneNumber:'', phoneNumber2:'', whatsapp:'',  gender:'', dob:'',religion:'',maritalStatus:'',bloodGroup:'', userProfile:'',
          },
          validationSchema:Yup.object().shape({
          //  name:Yup.string().min(3).max(50).required('please enter image name'),
          //   // name:Yup.string().min(3).max(20).matches('please provide a valid name').required('please enter full name Muhammad Ali'),
          //   shopName:Yup.string().min(4,'please enter atleast 4 characters').max(20,'maximum 20 charactors are allowed').required('Shop Name must be required'),
          //   // email:Yup.string().email().matches('please provide a valid email').required('email is required'),
          //   password: Yup.string().min(6,'password should be atleast 6 characters').max(15,'maximum characters are allowed 15').required('Password is required'),
          //   cpassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('confirm password is required'),
          //   userName:Yup.string().min(4,'please enter atleast 4 characters').max(15,'maximum characters are allowed 15').required('User Name is required'),
          // //  phoneNumber:Yup.string().min(8).max(15).matches('Phone number is not valid').required('Phone Number is required'),
          //   address:Yup.string().min(15,'please enter atleast 4 characters').required('Address is required'),
          //   userProfile:Yup.string().required('image is required'),
          //   age:Yup.string().required('valid date of birth required'),
          //   gender:Yup.string().required('gender required'),
               
        }),
       
         onSubmit:(values)=>{
          console.log(values,'val');
            const {state,district,name,   fatherName,  shopName, other,country,religion,maritalStatus,bloodGroup,
               email,  password,  facebook,  youtube,  insta,  website,userImage,
                city, address, phoneNumber, phoneNumber2, whatsapp,  gender, nic,dob,userProfile,describe}=values
       
          dispatch(postUserAction({country,state,district,name,   fatherName,  shopName, other,education,experience,
            email,  password,  fileName, facebook,  youtube,  insta,  website,professions,subProfessions,subOccupations,
             occupations, city, address, phoneNumber,describe, phoneNumber2, whatsapp,nic,  gender,userImage, dob,userProfile,religion,maritalStatus,bloodGroup,}))
           
            }})
            
            const handleContinue=(e)=>{
              setShowHide(e)
            }
            const handleOther=(e)=>{
              setShowHide(e)
            }
            const handleBack=(e)=>{
              setShowHide(e)
            }
        //     useEffect(() => {
        //   if(success===true && loading===false){
        //     // alert.success('category has been created successfully')
        //     Swal.fire({
        //       icon:'success',
        //       title: "success",
        //       text: "banner uploaded",
        //       timer:3000
        //     });
        //   }
        //   if(success===false && loading===false){
        //     alert.error('banner not created')
        //   }
        //     }, [alert,success,loading])
    //     useEffect(() => {
    //    dispatch(postStateAction())
    //     }, [dispatch])
   
// console.log(state,'state');
const handleGetEductaion=(e,i)=>{
    const {name,value}=e.target
    const list=[...education]
    list[i][name]=value
    setEducation(list)
}
const handleAddEducation=()=>{  setEducation([...education,{degree:'',passingYear:'', majorSubjects:'',board:'',grade:''}])}
const handleRemoveEducation=(i)=>{
const list=[...education];
list.splice(i,1);
setEducation(list)
}

const handleGetExperience=(e,i)=>{
    const {name,value}=e.target
    // console.log(name,'name',value,'vaalue')
    const list=[...experience]
    list[i][name]=value
    setExperience(list)
}
const handleAddExperience=()=>{  setExperience([...experience,{post:'',company:'',jobDescription:'',years:''}])}
const handleRemoveExperience=(i)=>{
  console.log(i,'remove');
const list=[...experience];
list.splice(i,1);
setExperience(list)
}

    return (
    <>
    {/* coutry state city */}
    {/* <div className="userdetails"> */}
    <form onSubmit={handleSubmit}>

   <div className="wrapper-country">
    

<div className="box">
<label htmlFor="country">Country <span className='staric'>*</span></label>
                                <select
                                  className="ms-3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="country"
                                  onClick={(e)=>dispatch(getStateAction(e.target.value))}
                                  >
                                 <option value=''>Country State</option>
                              
                                 {countries?.sort((a,z)=>a.name.toLowerCase()>z.name.toLowerCase()?1:-1).map((item,i)=>{
      const {isoCode,countryCode,_id,name}=item
      return(
        <>
   
        <option className='btn btn-info' key={i} value={[isoCode,countryCode,_id]}>{name}</option>
        </>
      )
    })}
                                </select>
  </div>
{/* state info */}

<div className="box">
<label htmlFor="sate">State </label>
<select
                                  className="ms-3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="state"
                                  onClick={(e)=>{dispatch(getDistrictAction(e.target.value));
                                 setValu(e.target.value)}}>
                                      <option value=''>Select State</option>
                                 {states?.map((item)=>{
      const {isoCode,countryCode,_id}=item
      return(
        <>
   
        <option className='btn btn-info' value={[isoCode,countryCode,_id]}>{item.name}</option>
        </>
        
      )
    })}
    <option className='btn btn-info' value='state'>Other</option>
    
   
    {/* <option className='btn btn-info'>Other</option> */}
                                </select>
                                {valu==='state'?<>
                                  <label htmlFor="name">New State</label>
                                <input type='text' className='w-75 ms-3 bg-primary' name='state' 
                                onChange={handleChange} onBlur={handleBlur}/></>:null 
                                }
                         
    {/* {valu==='state'?<input type='text'/>:null} */}
</div>
{/* district`` */}

<div className="box">
<label htmlFor="district">District</label>
<select
                                  className="ms-3"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name="district"
                                  onClick={(e)=>{
                                    setValu(e.target.value)}}
                                //   value={values.bloodGroup}
                                >
                                  <option value="">Select District</option>
                                  {district?.map((item)=>(
                                    <>
                                  <option value={item._id}>{item.name}</option>
                                    </>
                                  ))}
                                 
                                 <option value='district'>Other</option>
                               
                                </select>
                                {valu==='district'?<>
                                  <label htmlFor="name">New Distt</label>
                                <input type='text' className='w-75 ms-3 bg-primary' name='district' 
                                onChange={handleChange} onBlur={handleBlur}/></>:null 
                                }

</div>
{/* </div> */}

{/* <hr/> */}

<div className="box">
<label htmlFor="occupation">Occupation <span className='staric'>*</span></label>

                                <select
                                  className="ms-3"
                                  name='professions'                                                             
                                  onChange={(e)=>{dispatch(getSubProfessionAction(e.target.value));
                                  setValu(e.target.value);setProfessions(e.target.value)}}>
                                 <option value=''>Occupation</option>                              
                                 {profession?.map((item)=>{
                           const {professionName,_id}=item
      return(
        <>
   
        <option className='btn btn-info' name={`${professionName}`}  
        value={[professionName,_id]}>{professionName}</option>
        </>
      )
    })}
             <option className='btn btn-info' value='occupation'>Other</option>     
                                </select>                            
                                {valu==='occupation'?<>
                                  <label htmlFor="name">New Profession <span className='staric'>*</span></label>
                                <input type='text' className='w-75 ms-3 bg-primary' name='profession' 
                                 onChange={(e)=>setOccupations(e.target.value)} value={occupations}/></>:null 
                                }    
           
                                
                                </div>
    <div className="box">
    <label htmlFor="sub occupation">Sub Occupation <span className='staric'></span></label>
    <select
                                  className="ms-3"
                                  // onChange={(e)=>console.log(e.target.name,'fffffffffffffffffffffs')}
                                  onChange={e=>setSubProfessions(e.target.value)}
                                  onBlur={handleBlur}
                                  name='subProfession'
                                  
                                  onClick={(e)=>setValu(e.target.value)}
                                  >
                                 <option value=''>Sub Occupation</option>
                              
                                 {/* {profession?.map((item)=>{ */}
                             {subProfession?.sort((a,z)=>a.subProfessionName.toLowerCase()>z.subProfessionName.toLowerCase()?1:-1)
                              .map((item)=>{
      const {subProfessionName,professionId,_id}=item
      return(
        <>   
        {/* <input className='btn btn-info' name={subProfessionName} value={[professionId,subProfessionName]}/> */}
        <option className='btn btn-info' name='names' value={[professionId,subProfessionName]}>{subProfessionName}</ option>
        </>
      )
    })}
             <option className='btn btn-info' name='others' value='suboccupation'>Other</option>     
                                </select>
                                {valu==='suboccupation'?<>
                                  <label htmlFor="name">New Sub Profession</label>
                                <input type='text' className='w-75 ms-3 bg-primary' name='subProfession' 
                                 onChange={(e)=>setSubOccupations(e.target.value)} value={subOccupations}/></>:null 
                                }   
                            

</div>
   </div>

   <div className="box">
  <label htmlFor="discribe">Profession Details</label>
<textarea type='text' onChange={handleChange} onBlur={handleBlur} name='describe'
placeholder='I am a building Masson'

/>
</div>

<hr/>

  {/* persanol info  and professional info*/}
<div className="wrapper-country">
    <div className="box">
  <label htmlFor="name">Name <span className='staric'>*</span></label>
<input type='text' name='name' onChange={handleChange} onBlur={handleBlur}/>
<div className='error' style={{color:'red'}}>
  
  
  
   {touched.name && errors.name ? errors.name:''}
                      </div>
</div>
    <div className="box">
  <label htmlFor="Father Name">Father Name</label>
<input type='text' onChange={handleChange} onBlur={handleBlur} name='fatherName'/>
</div>
    <div className="box">
  <label htmlFor="cnic">NIC</label>
<input type='text' onChange={handleChange} onBlur={handleBlur} name='nic'/>
</div>
    <div className="box">
  <label htmlFor="contact">Contact # 1<span className='staric'>*</span></label>
<input type='text' onChange={handleChange} onBlur={handleBlur} name='phoneNumber'/>
</div>
    <div className="box">
  <label htmlFor="contact2">Contact # 1</label>
<input type='text' onChange={handleChange} onBlur={handleBlur}name='phoneNumber2'/>
</div>
    <div className="box">
  <label htmlFor="whatsapp">Whatsapp</label>
<input type='text' onChange={handleChange} onBlur={handleBlur} name='whatsapp'/>
</div>
<div className="box">
  <label htmlFor="religion">Religion</label>
<input type='text' name='religion'onChange={handleChange} onBlur={handleBlur}/>
</div>
    <div className="box">
  <label htmlFor="dob">Date of Birth</label>
<input type='text' onChange={handleChange} onBlur={handleBlur} name='dob'/>
</div>
    <div className="box">
  <label htmlFor="email" >E-Mail</label>
<input type='email' name='email' onChange={handleChange} onBlur={handleBlur}/>
</div>
    <div className="box">
  <label htmlFor="password">Password<span className='staric'>*</span></label>
<input type='password' name='password'onChange={handleChange} onBlur={handleBlur}/>
</div>
  
    <div className="box">
  <label htmlFor="cpassword">C Password<span className='staric'>*</span></label>
<input type='password' onChange={handleChange} onBlur={handleBlur}/>
</div>
    
    <div className="box">
  <label htmlFor="shopname">Shop Name</label>
<input type='text' onChange={handleChange} onBlur={handleBlur} name='shopName'/>
</div>
<div className="box">
  <label htmlFor="cnic">City<span className='staric'>*</span></label>
<input type='text' onChange={handleChange} onBlur={handleBlur} name='city'/>
</div>
    <div className="box">
  <label htmlFor="Address">Address</label>
<input type='text' onChange={handleChange} onBlur={handleBlur} name='address'/>
</div>


  {/* education info */}

  {/* experience */}

  {/* social link */}
  <div className="box">
  <label htmlFor="youtube">YouTube</label>
<input type='text' onChange={handleChange} onBlur={handleBlur} name='youtube'/>
</div>    <div className="box">
  <label htmlFor="google">Google</label>
<input type='text' onChange={handleChange} onBlur={handleBlur} name='google'/>
</div>    <div className="box">
  <label htmlFor="insta">Insta</label>
<input type='text' onChange={handleChange} onBlur={handleBlur}name='insta'/>
</div>    <div className="box">
  <label htmlFor="facebook">Web Site</label>
<input type='text' onChange={handleChange} onBlur={handleBlur}name='website'/>
</div>
   
   <div className="box">
<label className="form-label" for="form3Example3c">upload Image</label>
               
                    <input type="file" name='fileName' className="" style={{padding:'0px',marginLeft:'15px',border:'none'}}   
                    
                    onBlur={handleBlur}               
                    onChange={(e)=>{
                      let reader= new FileReader();
                      // console.log(reader,'reader');
                      reader.onload=(e)=>{
                  if(reader.readyState===2){
                          setPreview(reader.result)
                          setFieldValue('userImage',(reader.result));}
                      }
                      if(e.currentTarget.files[0].size>5000000){
                        alert.error('image size is greater than 2MB')
                        
                        return;
                      }
                      else{
                        reader.readAsDataURL(e.currentTarget.files[0]) 
                      }
                      // console.log(e.target.files[0].name,'file name');
                      setFileName(e.target.files[0].name)
                      }}
                    />
                    
                   
                  
                   </div>
                   
                   <div className="box">
    <label htmlFor="gender">Gender</label>
    <select                
                onChange={handleChange}  onBlur={handleBlur} name='gender'>
                    <option value="">select Gender</option>
                    <option value="Male">Male</option>
                    <option value="feMale">Female</option>
                    <option value="Not Mention">Not Recommanded</option>
                                   </select>
</div>
<div className="box">
    <label htmlFor="gender">Marital Status</label>
    <select                
                onChange={handleChange}  onBlur={handleBlur} name='maritalStatus'>
                    <option value="">select Status</option>
                    <option value="Male">Married</option>
                    <option value="feMale">UnMarried</option>
                    <option value="Not Mention">Not Recommanded</option>
                                   </select>
</div>
<div className="box">
    <label htmlFor="gender">Blood Group</label>
    <select                
                onChange={handleChange}  onBlur={handleBlur} name='bloodGroup'>
                  <option value="">Select Blood Group</option>
                                  
                                 
                                  <option value='a+'>A positive</option>
                                  <option value='b+'>B Positive</option>
                                  <option value='ab+'>AB Positive</option>
                                  <option value='o+'>O Possitive</option>
                                  <option value='a-'>A Negative</option>
                                  <option value='b-'>B Negative</option>
                                  <option value='ab-'>AB negative</option>
                                  <option value='o-'>O Negative</option>
                                 
                                   </select>
</div>
                 
                 
                 
                   </div>
                   

{/* Educational Information */}

                   <div className='text-center contact-detail bg-danger fw-bold'>Educational Information</div>
                   <div className="userSearch">
<table>
  
<tr>
<th style={{width:'20px'}}>S.No</th>

    <th style={{width:'120px'}}>degree</th>
    <th>Major Subjects</th>
    <th>Board/UV</th>
    <th>Passing Year</th>
    <th>Grade</th>
  </tr>
{education?.map((item,i)=>{
const {degree,grade,passingYear,majorSubjects,board}=item
  return(
  <>
  <tr>
  <td>{i+1}</td>
   <td> <input className='name' type='text'  onBlur={handleBlur} name='degree' onChange={(e)=>handleGetEductaion(e,i)}/></td>
        <td><input className='name' type='text'  onBlur={handleBlur} name='majorSubjects' onChange={(e)=>handleGetEductaion(e,i)}/></td>
        <td><input className='name' type='text' onBlur={handleBlur} name='board' onChange={(e)=>handleGetEductaion(e,i)}/></td>
        <td><input className='name' type='text'  onBlur={handleBlur} name='passingYear' onChange={(e)=>handleGetEductaion(e,i)}/></td>
        <td><input className='name' type='text'  onBlur={handleBlur} name='grade' onChange={(e)=>handleGetEductaion(e,i)}/></td>
        <td style={{width:'20px'}}>
          { 
   education.length!==1 &&
   <button className='btn-delete text-center align-item-center' onClick={()=>handleRemoveEducation(i)} key={i}>
    <DeleteIcon style={{color:'red',height:'40px',width:'40px'}}/></button>
}</td>
        <td style={{width:'20px'}}>{
   education.length-1===i &&
   <button className='btn-delete text-center align-item-center' onClick={()=>handleAddEducation(i)}><AddCircleIcon style={{color:'green',height:'40px',width:'40px'}}/></button>
}</td>
  </tr>
  </>
  )
})}

</table>
</div>


{/* experience Information */}
<div className='text-center contact-detail bg-danger fw-bold mt-4'>Professional Experience Information</div>
<div className="userSearch">
                   <table className='text-center mt-3'>
                    
 <tr>
    <th style={{width:'20px'}}>S.No</th>
    <th>Post</th>
    <th>Years From-To</th>
    <th>Company</th>
    <th>Job Des.</th>
     <th style={{width:'20px'}}>Delete</th>
    <th style={{width:'20px'}}>Add More</th>
   
  </tr>
{experience.map((item,i)=>{
    return(
        <>
       <tr>
        <td>{i+1}</td>
        <td><input className='name' type='text'onBlur={handleBlur} name='post' onChange={(e)=>handleGetExperience(e,i)}/></td>
        <td><input className='name' type='text'onBlur={handleBlur}name='years' onChange={(e)=>handleGetExperience(e,i)}/></td>
        <td><input className='name' type='text'onBlur={handleBlur} name='company' onChange={(e)=>handleGetExperience(e,i)}/></td>
        <td><input className='name' type='text'onBlur={handleBlur}name='jobDescription' onChange={(e)=>handleGetExperience(e,i)}/></td>
        <td><input className='name' type='text' onBlur={handleBlur}onChange={(e)=>handleGetExperience(e,i)}/></td>
        
        
        <td style={{width:'20px'}}>
          { 
   experience.length!==1 &&
   <button className='btn-delete text-center align-item-center' onClick={()=>handleRemoveExperience(i)} key={i}>
    <DeleteIcon style={{color:'red',height:'40px',width:'40px'}}/></button>
}</td>
        <td style={{width:'20px'}}>{
   experience.length-1===i &&
   <button className='btn-delete text-center align-item-center' onClick={()=>handleAddExperience(i)}><AddCircleIcon style={{color:'green',height:'40px',width:'40px'}}/></button>
}</td>
       </tr>

        </>
    )
})}
</table>
</div>

<button className='btn btn-warning' onClick={()=>dispatch(postCombineAction({occupations,subOccupations}))}>submit</button>




</form>

{/* </div> */}
    </>
  )
}

export default AcademyRegister