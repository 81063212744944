import axios from 'axios'
import {LOGIN_REQUEST,LOGIN_SUCCESS,LOGIN_FAIL,
  REGISTER_REQUEST,REGISTER_SUCCESS,REGISTER_FAIL,
  LOAD_USER_REQUEST,LOAD_USER_SUCCESS,LOAD_USER_FAIL,
    LOGOUT_SUCCESS,LOGOUT_FAIL

} from '../constant/userConstant'
export const registerUser=(userData)=>async(dispatch)=>{
  console.log(userData,'user datea');
  dispatch({type:REGISTER_REQUEST})
  try {
    const config = { headers: { "Content-Type": "multipart/form-data" } };
      const {data}=await axios.post('/register',userData,config)
      console.log(data,'data');
  dispatch({type:REGISTER_SUCCESS,
      payload:data})
      // localStorage.setItem('userInfo',JSON.stringify(data))
  } catch (error) {
      dispatch({type:REGISTER_FAIL,
          payload: error,
      })}}




export const postLoginAction = (loginData) => async(dispatch)=>{
    console.log(loginData);
    dispatch({type:LOGIN_REQUEST})
    try {
        const config = { headers: { "Content-Type": "application/json" } };
        const {data}=await axios.post('/login',loginData,config)
        console.log(data,'data');
    dispatch({type:LOGIN_SUCCESS,
        payload:data})
        localStorage.setItem('userInfo',JSON.stringify(data))
    } catch (error) {
        dispatch({type:LOGIN_FAIL,
            payload: error,
        })}}

        export const loadUser = () => async (dispatch,getState) => {
            try {
                // const {postLoginReducer:{userInfo}}=getState()
                const loadUser=JSON.parse(localStorage.getItem('userInfo'))
                // console.log(loadUser.token,'loqad user');
                // console.log(userInfo,'user info');
              dispatch({ type: LOAD_USER_REQUEST });
              const config = { headers: { "Content-Type": "application/json",
            Authorization:`Bearer ${loadUser.token}` 
        }
        };

              const {data} = await axios.get('/profile',config);
          // console.log(data,'user data');
              dispatch({ type: LOAD_USER_SUCCESS, payload: data });
            } catch (error) {
              dispatch({ type: LOAD_USER_FAIL, payload: error });
            }
          };

          export const logout = () => async (dispatch) => {
            try {
             
              await axios.get(`/logout`); 
           localStorage.removeItem('userInfo')
              dispatch({ type: LOGOUT_SUCCESS });
            } catch (error) {
              dispatch({ type: LOGOUT_FAIL, payload: error });
            }
          };