import axios from "axios";
import { GET_ALL_DISTRICT_FAIL, GET_ALL_DISTRICT_REQUEST, GET_ALL_DISTRICT_SUCCESS, GET_DISTRICT_FAIL, GET_DISTRICT_REQUEST, GET_DISTRICT_SUCCESS, POST_DISTRICT_FAIL,
     POST_DISTRICT_REQUEST, POST_DISTRICT_SUCCESS } from "../constant/districtConstant";

export const postDistrictAction = (postdistrict) => async (dispatch) => {
    console.log(postdistrict,'post DISTRICT action');
      try {
        dispatch({ type: POST_DISTRICT_REQUEST });
    
        const { data } = await axios.post('http://localhost:4000/admin/add/district',postdistrict);
    console.log(data.district,'get all district actions');
        dispatch({
          type: POST_DISTRICT_SUCCESS,
          payload: data.district
        });
      } catch (error) {
        dispatch({
          type:  POST_DISTRICT_FAIL,
          payload: error.response
        });
      }
    };

export const getDistrictAction = (isoCode) => async (dispatch) => {
  console.log(isoCode,'state insertion new');
  let abc=isoCode.split(",")
  // console.log(abc[0],'abc');
 let stateCode=abc[0]
 let countryCode=abc[1]
    // let 
      try {
        dispatch({ type: GET_DISTRICT_REQUEST });
    
        const { data } = await axios.post('http://localhost:4000/get/district',{stateCode,countryCode});
    // console.log(data.district,'get all district actions');
        dispatch({
          type: GET_DISTRICT_SUCCESS,
          payload: data.district
        });
      } catch (error) {
        dispatch({
          type:  GET_DISTRICT_FAIL,
          payload: error.response
        });
      }
    };
export const getAllDistrictAction = (isoCode) => async (dispatch) => {

      try {
        dispatch({ type: GET_ALL_DISTRICT_REQUEST });
    
        const { data } = await axios.get('http://localhost:4000/get/alldistrict');
    console.log(data.district,'get all district actions');
        dispatch({
          type: GET_ALL_DISTRICT_SUCCESS,
          payload: data.district
        });
      } catch (error) {
        dispatch({
          type:  GET_ALL_DISTRICT_FAIL,
          payload: error.response
        });
      }
    };