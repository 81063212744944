import React from 'react'
import { NavLink } from 'react-router-dom'

const OurServices = () => {
  return (
  <>
  <div className="container-fluid">
    <div className="row border-circle">
    <div className="menu bg-primary fw-bold ">
            <ul className='ul-list text-dark'>
                <li><NavLink to='/software' className='text-light' style={{textDecoration:'none'}}>Software</NavLink></li>
                <li><NavLink to='/web-development' className='text-light' style={{textDecoration:'none'}}>Web Development</NavLink></li>
                <li><NavLink to='/web-designing' className='text-light' style={{textDecoration:'none'}}>Web Design</NavLink></li>
                <li><NavLink to='/seo'className='text-light' style={{textDecoration:'none'}}>SEO</NavLink></li>
                <li><NavLink to='/vidoe-editing' className='text-light' style={{textDecoration:'none'}}>Video Editing</NavLink></li>
                <li><NavLink to='/photo-editing' className='text-light' style={{textDecoration:'none'}}>Photo Editing</NavLink></li>
           
            </ul>
           </div>
    </div>
  </div>
  
  </>
  )
}

export default OurServices