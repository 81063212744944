import { TextareaAutosize } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { updateFeatureAction } from '../redux/action/serviceAction'

const UpdateFeature = () => {
  const {id}=useParams()
  const {singleFeature}=useSelector(state=>state.getSingleFeatureReducer)
  const [list, setList] = useState(singleFeature.list)
  console.log(singleFeature,'single feature');
  const dispatch=useDispatch()

    // console.log(id,'id id id ');

  const postData=()=>{
    dispatch(updateFeatureAction({id,list}))
  }


  
    
  return (
  <>
 <div className="container-fluid">
  <div className="row">
  <TextareaAutosize type='text' value={list} onChange={(e)=>setList(e.target.value)}/><br/>
  <button className='btn btn-success' onClick={postData}>update</button>

  </div>
 </div>
  </>
  )
}

export default UpdateFeature