import axios from "axios";
import { GET_PROFESSION_FAIL, GET_PROFESSION_REQUEST, GET_PROFESSION_SUCCESS, POST_PROFESSION_FAIL, POST_PROFESSION_REQUEST, POST_PROFESSION_SUCCESS } from "../constant/professionConstant";

export const postProfessionAction = (education) => async (dispatch) => {
    console.log(education,'post PROFESSION action');
    const {name}=education
      try {
        dispatch({ type: POST_PROFESSION_REQUEST });
    
        const { data } = await axios.post('http://localhost:4000/admin/add/profession',({name:name}));
    console.log(data.profession,'get all profession actions');
        dispatch({
          type: POST_PROFESSION_SUCCESS,
          payload: data.profession
        });
      } catch (error) {
        dispatch({
          type:  POST_PROFESSION_FAIL,
          payload: error.response
        });
      }
    };

export const getAllProfessionAction = () => async (dispatch) => {
    // console.log('get all profession action');
      try {
        dispatch({ type: GET_PROFESSION_REQUEST });
    
        const { data } = await axios.get('http://localhost:4000/get/profession');
    console.log(data.profession,'get all profession actions');
        dispatch({
          type: GET_PROFESSION_SUCCESS,
          payload: data.profession
        });
      } catch (error) {
        dispatch({
          type:  GET_PROFESSION_FAIL,
          payload: error.response
        });
      }
    };