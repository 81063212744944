import React from 'react'
import './home.css'
import Location from '../../location/Location'
import Contact from '../../contact/Contact'
import Blog from '../../blog/Blog'
import TestMonial from '../../testimonial/TestMonial'
import Services from '../../services/Services'
import Wellcome from '../../wellcome/Wellcome'
import Banner from '../../banner/Banner'
import HappyCustomers from '../../Happy/HappyCustomers'
import Pos from '../../pos/Pos.jsx'
import PricePlan from '../../price plan/PricePlan.jsx'
import Team from '../../Team/Team.jsx'
const Home = () => {
  return (
    <>
    {/* <Banner/> */}
  <Wellcome/>
  <Services/>
  <PricePlan/>
  <HappyCustomers/>
  {/* <TestMonial/> */}
  <Team/>
  {/* <Pos/> */}
  <Contact/>
  <Location/>
     
    </>
  )
}

export default Home