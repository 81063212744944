import axios from "axios";
import { GETCOMBINESERVICE_SERVICE_FAIL, GETCOMBINESERVICE_SERVICE_REQUEST, GETCOMBINESERVICE_SERVICE_SUCCESS,
    GETCOM_SERVICE_FAIL, GETCOM_SERVICE_REQUEST, GETCOM_SERVICE_SUCCESS,
    GET_FINALSERVICE_REQUEST,GET_FINALSERVICE_SUCCESS,GET_FINALSERVICE_FAIL,
    GET_FINALIMAGE_REQUEST,GET_FINALIMAGE_SUCCESS,GET_FINALIMAGE_FAIL
} from "../constant/serviceConstant";

export const getserviceById = (slug) => async(dispatch)=>{
    console.log(slug,'slug');
    dispatch({type:GETCOMBINESERVICE_SERVICE_REQUEST})
    try {
        const {data}=await axios.get(`/service/${slug}`)
        // console.log(data,'serviceAction');
    dispatch({type:GETCOMBINESERVICE_SERVICE_SUCCESS,
        payload:(data.service[0])})
    } catch (error) {
        dispatch({type:GETCOMBINESERVICE_SERVICE_FAIL,
            payload: error.data,
        })}}
export const getservice2nd = (slug) => async(dispatch)=>{
    console.log(slug,'slug');
    dispatch({type:GETCOM_SERVICE_REQUEST})
    try {
        const {data}=await axios.get(`/services/${slug}`)
        console.log(data.combine,'serviceAction');
    dispatch({type:GETCOM_SERVICE_SUCCESS,
        payload:data.combine})
    } catch (error) {
        dispatch({type:GETCOM_SERVICE_FAIL,
            payload: error.data,
        })}
    }
    
       
       
       
       
       
        export const getcomservice = (id) => async(dispatch)=>{
            // console.log(id);
            dispatch({type:GET_FINALSERVICE_REQUEST})
            try {
                const {data}=await axios.get(`/service/${id}`)
                // console.log(data,'serviceAction');
            dispatch({type:GET_FINALSERVICE_SUCCESS,
                payload:data.combine})
            } catch (error) {
                dispatch({type:GET_FINALSERVICE_FAIL,
                    payload: error,
                })}}
       
        export const getimageservice = (id) => async(dispatch)=>{
            console.log(id,'imag eis');
            dispatch({type:GET_FINALIMAGE_REQUEST})
            try {
                const {data}=await axios.get(`/new/service/combine/${id}`)
                console.log(data,'serviceAction');
            dispatch({type:GET_FINALIMAGE_SUCCESS,
                payload:data.imgcombine})
            } catch (error) {
                dispatch({type:GET_FINALIMAGE_FAIL,
                    payload: error,
                })}}
              
