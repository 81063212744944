import axios from 'axios'
import { SERVICE_UPLOAD_FAIL, SERVICE_UPLOAD_REQUEST, SERVICE_UPLOAD_SUCCESS,
    GET_SERVICE_FAIL, GET_SERVICE_REQUEST, GET_SERVICE_SUCCESS,
    GET_FINALSERVICE_FAIL, GET_FINALSERVICE_REQUEST, GET_FINALSERVICE_SUCCESS,
    FINALSERVICE_FAIL, FINALSERVICE_REQUEST, FINALSERVICE_SUCCESS,
    UPDATE_SERVICE_REQUEST,UPDATE_SERVICE_SUCCESS,UPDATE_SERVICE_FAIL,
    DELETE_SERVICE_FAIL, DELETE_SERVICE_REQUEST, DELETE_SERVICE_SUCCESS,
     GET_FEATURE_REQUEST, GET_FEATURE_SUCCESS, GET_FEATURE_FAIL,
     UPDATE_FEATURE_REQUEST, UPDATE_FEATURE_SUCCESS, UPDATE_FEATURE_FAIL,
     GET_SINGLEFEATURE_REQUEST, GET_SINGLEFEATURE_SUCCESS, GET_SINGLEFEATURE_FAIL
} from '../constant/serviceConstant';

export const postServiceAction = (serviceData) => async(dispatch)=>{
    console.log(serviceData,'service data');
dispatch({type:SERVICE_UPLOAD_REQUEST})
try {
    const data=await axios.post('/admin/add/service',serviceData)
    // console.log(data.status,'data');
dispatch({type:SERVICE_UPLOAD_SUCCESS,
    payload:data})
} catch (error) {
    dispatch({type:SERVICE_UPLOAD_FAIL,
        payload:error,
    })}
}
    //get banner
    export const getFServiceAction = (slug) => async(dispatch)=>{
        dispatch({type:FINALSERVICE_REQUEST})
        try {
            const {data}=await axios.get(`/service/combine/${slug}`)
            // console.log(data.combine,'data data data');
            dispatch({type:FINALSERVICE_SUCCESS,
                payload:data.combine})
        } catch (error) {
            console.log(error);
            dispatch({type:FINALSERVICE_SUCCESS,
                payload:error
        })

    }}

    export const getserviceAction = () => async(dispatch)=>{
        dispatch({type:GET_SERVICE_REQUEST})
        try {
            const {data}=await axios.get('/services')
            console.log(data,'serviceAction');
        dispatch({type:GET_SERVICE_SUCCESS,
            payload:data.service})
        } catch (error) {
            dispatch({type:GET_SERVICE_FAIL,
                payload: error,
            })}}
    export const getFeatureAction = (e) => async(dispatch)=>{
        console.log(e);
        dispatch({type:GET_FEATURE_REQUEST})
        try {
            const {data}=await axios.get(`/feature/${e}`)
            console.log(data,'serviceAction');
        dispatch({type:GET_FEATURE_SUCCESS,
            payload:data.feature})
        } catch (error) {
            dispatch({type:GET_FEATURE_FAIL,
                payload: error,
            })}}
    export const getSingleFeatureAction = (e) => async(dispatch)=>{
        console.log(e);
        dispatch({type:GET_SINGLEFEATURE_REQUEST})
        try {
            const {data}=await axios.get(`/update/feature/${e}`)
            console.log(data,'serviceAction');
        dispatch({type:GET_SINGLEFEATURE_SUCCESS,
            payload:data.singleFeature})
        } catch (error) {
            dispatch({type:GET_SINGLEFEATURE_FAIL,
                payload: error,
            })}}
          
        export const updateServiceAction=(getData)=>async(dispatch)=>{
            console.log(getData,'get data');
    
                dispatch({type:UPDATE_SERVICE_REQUEST})
                try {
                    const config = { headers: { "Content-Type": "application/json" } };
                    const {data}=await axios.put(`/services/${getData.id}`,getData ,config)
                    console.log(data,'data');
                dispatch({type:UPDATE_SERVICE_SUCCESS,
                    payload:data.service})
                } catch (error) {
                    dispatch({type:UPDATE_SERVICE_FAIL,
                        payload: error
                    })}}


        export const updateFinalServiceAction=(getData)=>async(dispatch)=>{
            console.log(getData,'get data');
    
                dispatch({type:UPDATE_SERVICE_REQUEST})
                try {
                    const config = { headers: { "Content-Type": "application/json" } };
                    const {data}=await axios.put(`/admin/finalservice/${getData.slug}`,getData ,config)
                    console.log(data);
                dispatch({type:UPDATE_SERVICE_SUCCESS,
                    payload:data.service})
                } catch (error) {
                    dispatch({type:UPDATE_SERVICE_FAIL,
                        payload: error
                    })}}
                    
        export const updateFeatureAction=(id)=>async(dispatch)=>{
            console.log(id,'get data');
    
                dispatch({type:UPDATE_FEATURE_REQUEST})
                try {
                    const config = { headers: { "Content-Type": "application/json" } };
                    const {data}=await axios.put(`/update/feature/${id.id}`,id,config)
                    console.log(data,'list date');
                dispatch({type:UPDATE_FEATURE_SUCCESS,
                    payload:data.feature.list})
                } catch (error) {
                    dispatch({type:UPDATE_FEATURE_FAIL,
                        payload: error
                    })}}
