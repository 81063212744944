import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateWellcomeAction } from '../redux/action/wellcomeAction'
import { useParams } from 'react-router-dom'
import { useAlert } from 'react-alert'
import { getserviceAction, updateServiceAction } from '../redux/action/serviceAction'
import { TextareaAutosize } from '@mui/material'


const UpdateService = () => {
    const {id}=useParams()
    const dispatch=useDispatch()
    const alert=useAlert()
    const {service}=useSelector(state=>state.combineService)
    console.log(service,'service');
    const [details, setDetails] = useState(service.details)
    const [credit, setCredit] = useState(service.credit)
    const [name, setName] = useState(service.name)
    const postData=async()=>{
        dispatch(updateServiceAction({id,details,name,credit}))
    }
    useEffect(() => {
        dispatch(updateServiceAction())
        dispatch(getserviceAction())
      }
    , [dispatch])
    
  return (
   <>
<div className="container">
    <div className="row d-flex flex-column">
    <label htmlFor="title">Title:</label>
<input type="text" className='w-75' placeholder='title' value={name}  onChange={(e)=>setName(e.target.value)}/><br/>

<label htmlFor="Details">Details:</label>
   <TextareaAutosize placeholder='details' className='w-75' value={details}  onChange={(e)=>setDetails(e.target.value)}/><br/>

   <label htmlFor="Credit">Credit:</label>
   <TextareaAutosize placeholder='credit' className='w-75' value={credit}  onChange={(e)=>setCredit(e.target.value)}/><br/>
   <button className='btn btn-success w-25' onClick={postData}>update</button>
    
   
    </div>
</div>

   </>
  )
}

export default UpdateService