import { DETAILS_UPLOAD_FAIL, DETAILS_UPLOAD_REQUEST, DETAILS_UPLOAD_SUCCESS,
    GET_DETAILS_FAIL, GET_DETAILS_REQUEST, GET_DETAILS_SUCCESS,
    GET_SINGLE_DETAILS_FAIL, GET_SINGLE_DETAILS_REQUEST, GET_SINGLE_DETAILS_SUCCESS,
    DELETE_DETAILS_FAIL, DELETE_DETAILS_REQUEST, DELETE_DETAILS_SUCCESS
} from '../constant/detailsConstant';
export const getDetailsReducer = (state = { details: [] }, action) => {
    switch (action.type) {
        case GET_DETAILS_REQUEST:
         
            return {
              loading: true,
              details: [],
            }
        case GET_DETAILS_SUCCESS:
         console.log(action.payload);
            return {
              loading: false,
              details:action.payload,
            }
        case GET_DETAILS_FAIL:
         
            return {
              loading: false,
              service: action.payload,
            }
        
            default:
                return state
        }

}

export const getSingleDetailsReducer = (state = { details: {} }, action) => {
    switch (action.type) {
        case GET_SINGLE_DETAILS_REQUEST:
         
            return {
              loading: true,
              details: {},
            }
        case GET_SINGLE_DETAILS_SUCCESS:
         console.log(action.payload);
            return {
              loading: false,
              details:action.payload,
            }
        case GET_SINGLE_DETAILS_FAIL:
         
            return {
              loading: false,
              service: action.payload,
            }
        
            default:
                return state
        }

}