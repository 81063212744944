import React, { useState } from 'react'
import axios from 'axios'
import Div from '../div/Div'
import { useAlert } from 'react-alert'
const PostJob = () => {
    const alert=useAlert()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [fieldValue,setFieldValue] = useState('')
    const [phone, setPhone] = useState('')
    const [budget, setBudget] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [pdfFile, setPdfFile] = useState('')
    const [image, setImage] = useState('')
    // const [pdfFile, setFileName] = useState('')
    const [images, setImages] = useState([]);
 const [imagesName, setImagesName] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);



    const createProductImagesChange = (e) => {
           
        const files = Array.from(e.target.files);
                   

    // console.log(abc,'abc');
        setImages([]);
        setImagesPreview([]);
     

        files.forEach((file) => {
      
          const reader = new FileReader();
    
          reader.onload = () => {
            if (reader.readyState === 2) {
              setImagesPreview((old) => [...old, reader.result]);
              setImages((old) => [...old, reader.result]);
                      
            }
          };
    
          reader.readAsDataURL(file);

          setImagesName(files.map((item)=>(
            (item.name)
          )))
        
     
        });  if(images.length>5){
            alert.error('you can upload only 5 pictures')
            
            return
        }
        
                  }


    const postContact=async(e)=>{
        try {
            if(name==='' || message==='' ||phone===''){
                alert.error('All * fields are requied')
                return
            }
            await axios.post('/contact',{name,subject,message,email,phone,
                budget,fieldValue,imagesName,images,pdfFile}).then((res)=>{
               if(res.status===200){
                alert.success('your message has been sent')
               }
               else{
                alert.error('something wen wrong')
               }
            })
                }
        catch (error) {
         console.log(error);   
        }}

  return (
 <>
 {/* <!--Section: Contact v.2--> */}



 <h3 className="pic-pic"> Post Your Job
    {/* <Div/> */}
    </h3>
    <div className="container-fluid w-100">


<div className="row">
<section className="mb-4 text-light contact">

    {/* <!--Section heading--> */}
  
    {/* <!--Section description--> */}
    <p className="text-center w-responsive mx-auto mt-3 bg-dark">
        Please Post Your JOb With Details...
       </p>

    <div className="row">

        {/* <!--Grid column--> */}
        <div className="col-md-12 mb-md-0 mb-5">
            <form id="contact-form" name="contact-form" action="" method="POST" className='ms-3'>

                {/* <!--Grid row--> */}
                <div className="row text-center">

                    {/* <!--Grid column--> */}
                    <div className="col-md-6 text-center">
                        <div className="md-form mb-0 text-center">
                        <label for="name" className="">Your name<span className='text-danger'>*</span></label>
                            <input type="text" id="name" name="name" value={name} onChange={(e)=>setName(e.target.value)} className="form-control"/>
                          
                        </div>
                    </div>
                    {/* <!--Grid column--> */}

                    {/* <!--Grid column--> */}
                    <div className="col-md-6">
                        <div className="md-form mb-0">
                        <label for="email" className="">Your email</label>
                            <input type="text" id="email" name="email" className="form-control"
                            value={email} onChange={(e)=>setEmail(e.target.value)}
                            />
                           
                        </div>
                    </div>
                    {/* <!--Grid column--> */}

                </div>
                {/* <!--Grid row--> */}

                {/* <!--Grid row--> */}
                            <div className="row">
                    <div className="col-md-6">
                        <div className="md-form mb-0">
                        <label for="subject" className="">Phone Number <span className='text-danger'>*</span></label>
                            <input type="text" id="subject" name="subject" className="form-control"
                            value={phone} onChange={(e)=>setPhone(e.target.value)}
                            />
                      
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="md-form mb-0">
                        <label for="subject" className="">Your Estimated Budget  in $ USD</label>
                           <input type="text" id="subject" name="subject" className="form-control"
                            value={budget} onChange={(e)=>setBudget(e.target.value)}
                            />
                      
                        </div>
                    </div>
                </div>
                {/* <!--Grid row--> */}
                <div className="row">
                    {/* pdf  */}
                    <div className="col-md-6">
                        <div className="md-form mb-0">
                        <label for="pdffile" className="">Select A Pdf File <span className='text-danger'>*</span></label>
                        <input type="file" className="form-control ms-3" 
                        accept='application/pdf'                  
                    onChange={(e)=>{
                      let reader= new FileReader();
                       reader.onload=(e)=>{
                  if(reader.readyState===2){
                        //   setPreview(reader.result)
                          setFieldValue(reader.result)}
                      }
                      reader.readAsDataURL(e.currentTarget.files[0]) 
                      setPdfFile(e.target.files[0].name)
                      }}
                    />
                      
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="md-form mb-0">
                        <label for="image" className="">Upload image </label>
                           <input type="file" id="image" name="image" className="form-control"
                           accept="image/*"
                           onChange={createProductImagesChange}
                           multiple />
                      
                        </div>
                    </div>
                </div>
                {/* <!--Grid row--> */}
                <div className="row">

                    {/* <!--Grid column--> */}
                    <div className="col-md-12">

                        <div className="md-form">
                        <label for="message">Your message</label>
                            <textarea type="text" id="message" name="message" rows="2" className="form-control md-textarea"
                            value={message} onChange={(e)=>setMessage(e.target.value)}
                            
                            ></textarea>
                            
                        </div>

                    </div>
                </div>
                {/* <!--Grid row--> */}

            </form>

            <div className="text-center text-md-left m-5z">
                <a className="btn btn-primary mt-3 mb-3" onClick={postContact}>Send</a>
            </div>
            <div className="status"></div>
        </div>
        {/* <!--Grid column--> */}

        {/* <!--Grid column--> */}
  
        {/* <!--Grid column--> */}

    </div>

</section>
{/* <!--Section: Contact v.2--> */}
 
</div>
 </div>
 </>
  )
}

export default PostJob