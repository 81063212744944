import axios from 'axios'
import { FINAL_UPLOAD_FAIL, FINAL_UPLOAD_REQUEST, FINAL_UPLOAD_SUCCESS,
    GET_FINAL_FAIL, GET_FINAL_REQUEST, GET_FINAL_SUCCESS,
    GET_SINGLE_FINAL_FAIL, GET_SINGLE_FINAL_REQUEST, GET_SINGLE_FINAL_SUCCESS,
    DELETE_FINAL_FAIL, DELETE_FINAL_REQUEST, DELETE_FINAL_SUCCESS
} from '../constant/finalConstant';
export const getFinalAction = (slug) => async(dispatch)=>{
    dispatch({type:GET_FINAL_REQUEST})
    try {
        
        const {data}=await axios.get(`/get/final/${slug}`)
        console.log(data);
    dispatch({type:GET_FINAL_SUCCESS,
        payload:data.final})
    } catch (error) {
        dispatch({type:GET_FINAL_FAIL,
            payload: error.response.data,
        })}}
        // SINGLE
export const getSingleFinalAction = (id) => async(dispatch)=>{
console.log(id,'id id id ');
    dispatch({type:GET_SINGLE_FINAL_REQUEST})
    try {
        const {data}=await axios.get(`/final/${id}`)
        console.log(data,'data');
    dispatch({type:GET_SINGLE_FINAL_SUCCESS,
        payload:data.combine})
    } catch (error) {
        dispatch({type:GET_SINGLE_FINAL_FAIL,
            payload: error.response.data,
        })}}

export const updateSingleFinalAction = (id,title,description) => async(dispatch)=>{
console.log(id,'id id id ');
    dispatch({type:GET_SINGLE_FINAL_REQUEST})
    try {
        const config = { headers: { "Content-Type": "application/json" } };
        const {data}=await axios.put(`/final/${id.id}`,id,config)
        console.log(data,'data data data');
    dispatch({type:GET_SINGLE_FINAL_SUCCESS,
        payload:data.service})
    } catch (error) {
        dispatch({type:GET_SINGLE_FINAL_FAIL,
            payload: error.response.data,
        })}}