import axios from 'axios'
import { DETAILS_UPLOAD_FAIL, DETAILS_UPLOAD_REQUEST, DETAILS_UPLOAD_SUCCESS,
    GET_DETAILS_FAIL, GET_DETAILS_REQUEST, GET_DETAILS_SUCCESS,
    GET_SINGLE_DETAILS_FAIL, GET_SINGLE_DETAILS_REQUEST, GET_SINGLE_DETAILS_SUCCESS,
    UPDATE_SINGLE_DETAILS_FAIL, UPDATE_SINGLE_DETAILS_REQUEST, UPDATE_SINGLE_DETAILS_SUCCESS,
    DELETE_DETAILS_FAIL, DELETE_DETAILS_REQUEST, DELETE_DETAILS_SUCCESS
} from '../constant/detailsConstant';

export const getDetailsAction = (e) => async(dispatch)=>{
    console.log(e);
    dispatch({type:GET_DETAILS_REQUEST})
    try {
        const {data}=await axios.get(`/details/${e}`)
        console.log(data,'deatils actions');
    dispatch({type:GET_DETAILS_SUCCESS,
        payload:data.details})
    } catch (error) {
        dispatch({type:GET_DETAILS_FAIL,
            payload: error,})}}

export const getSingleDetailsAction = (id) => async(dispatch)=>{
    console.log(id);
    dispatch({type:GET_SINGLE_DETAILS_REQUEST})
    try {
        const {data}=await axios.get(`/get/details/${id}`)
        console.log(data,'deatils actions');
    dispatch({type:GET_SINGLE_DETAILS_SUCCESS,
        payload:data.details})
    } catch (error) {
        dispatch({type:GET_SINGLE_DETAILS_FAIL,
            payload: error,})}}


            // update details 
export const updateDetailsAction = (id) => async(dispatch)=>{
    console.log(id);
    dispatch({type:UPDATE_SINGLE_DETAILS_REQUEST})
    try {
        const config = { headers: { "Content-Type": "application/json" } };
        const {data}=await axios.put(`/update/details/${id.id}`,id,config)
        console.log(data,'deatils actions');
    dispatch({type:UPDATE_SINGLE_DETAILS_SUCCESS,
        payload:data.service})
    } catch (error) {
        dispatch({type:UPDATE_SINGLE_DETAILS_FAIL,
            payload: error,})}}