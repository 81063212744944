import axios from 'axios'
import { WELLCOME_POST_FAIL, WELLCOME_POST_REQUEST, WELLCOME_POST_SUCCESS,
    GET_WELLCOME_FAIL, GET_WELLCOME_REQUEST, GET_WELLCOME_SUCCESS,
    UPDATE_WELLCOME_FAIL, UPDATE_WELLCOME_REQUEST, UPDATE_WELLCOME_SUCCESS,
    DELETE_WELLCOME_FAIL, DELETE_WELLCOME_REQUEST, DELETE_WELLCOME_SUCCESS
} from '../constant/wellcomeConstant';

export const updateWellcomeAction = (form) => async(dispatch)=>{
    dispatch({type:UPDATE_WELLCOME_REQUEST})
    try {
        const config = { headers: { "Content-Type": "application/json" } };
        const {data}=await axios.put(`/admin/wellcome/${form.id}`,form ,config)
    dispatch({type:UPDATE_WELLCOME_SUCCESS,
        payload:data})
    } catch (error) {
        dispatch({type:UPDATE_WELLCOME_FAIL,
            payload: error
        })}}
export const getWellcomeAction = () => async(dispatch)=>{
    dispatch({type:GET_WELLCOME_REQUEST})
    try {
        const {data}=await axios.get('/get/wellcome')
        // console.log(data.wellcome[0]);
    dispatch({type:GET_WELLCOME_SUCCESS,
        payload:data.wellcome[0]})
    } catch (error) {
        dispatch({type:GET_WELLCOME_FAIL,
            payload: error.response.data,
        })}}
        // POST REQUEST
        export const postWellcomeAction = (wellcome) => async(dispatch)=>{
            console.log(wellcome,'banner data');
        dispatch({type:WELLCOME_POST_REQUEST})
        try {
            const {data,message}=await axios.post('/admin/add/wellcome',wellcome)
        dispatch({type:WELLCOME_POST_SUCCESS,
            payload:data,message})
        } catch (error) {
            dispatch({type:WELLCOME_POST_FAIL,
                payload: error.response.data,
            })}}