import axios from "axios";
import { GET_ALL_STATE_FAIL, GET_ALL_STATE_REQUEST, GET_ALL_STATE_SUCCESS, GET_STATE_FAIL, GET_STATE_REQUEST, GET_STATE_SUCCESS, POST_STATE_FAIL,
     POST_STATE_REQUEST, POST_STATE_SUCCESS } from "../constant/stateConstant";

export const postStateAction = (postState) => async (dispatch) => {
    console.log(postState,'post STATE action');
      try {
        dispatch({ type: POST_STATE_REQUEST });
    
        const { data } = await axios.post('http://localhost:4000/admin/add/state',postState);
    console.log(data.state,'get all state actions');
        dispatch({
          type: POST_STATE_SUCCESS,
          payload: data.state
        });
      } catch (error) {
        dispatch({
          type:  POST_STATE_FAIL,
          payload: error.response
        });
      }
    };

export const getStateAction = (isoCode) => async (dispatch) => {
  console.log(isoCode,'get all countries action in state');
  const Code=isoCode.split(",")
  const countryCode=Code[1]
      try {
        dispatch({ type: GET_STATE_REQUEST });
        const { data } = await axios.post('http://localhost:4000/get/states',{countryCode});
        dispatch({
          type: GET_STATE_SUCCESS,
          payload: data.state
        });
      } catch (error) {
        dispatch({
          type:  GET_STATE_FAIL,
          payload: error.response
        });
      }
    };
export const getAllStateAction = () => async (dispatch) => {

      try {
        dispatch({ type: GET_ALL_STATE_REQUEST });
    
        const { data } = await axios.get('http://localhost:4000/get/allstates');
    // console.log(data.state,'get all state actions');
        dispatch({
          type: GET_ALL_STATE_SUCCESS,
          payload: data.state
        });
      } catch (error) {
        dispatch({
          type:  GET_ALL_STATE_FAIL,
          payload: error.response
        });
      }
    };