export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_REQUEST = "LOGIN_REQUEST";
export const REGISTER_SUCCESS = "LOGIN_SUCCESS";
export const REGISTER_FAIL = "LOGIN_FAIL";

export const LOAD_USER_REQUEST="LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS="LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL="LOAD_USER_FAIL";

export const LOGOUT_SUCCESS="LOAD_USER_SUCCESS";
export const LOGOUT_FAIL="LOAD_USER_FAIL";
export const POST_USER_REQUEST="POST_USER_REQUEST"
export const POST_USER_SUCCESS="POST_USER_SUCCESS"
export const POST_USER_FAIL="POST_USER_FAIL"

export const LOGIN_USER_REQUEST="LOGIN_USER_REQUEST"
export const LOGIN_USER_SUCCESS="LOGIN_USER_SUCCESS"
export const LOGIN_USER_FAIL="LOGIN_USER_FAIL"



export const GET_USER_REQUEST="GET_USER_REQUEST"
export const GET_USER_SUCCESS="GET_USER_SUCCESS"
export const GET_USER_FAIL="GET_USER_FAIL"

export const GET_ALL_USER_REQUEST="GET_ALL_USER_REQUEST"
export const GET_ALL_USER_SUCCESS="GET_ALL_USER_SUCCESS"
export const GET_ALL_USER_FAIL="GET_ALL_USER_FAIL"

export const GET_USER_DETAILS_REQUEST="GET_USER_DETAILS_REQUEST"
export const GET_USER_DETAILS_SUCCESS="GET_USER_DETAILS_SUCCESS"
export const GET_USER_DETAILS_FAIL="GET_USER_DETAILS_FAIL"

export const PASSWORD_RESET_EMAIL_REQUEST="PASSWORD_RESET_EMAIL_REQUEST"
export const PASSWORD_RESET_EMAIL_SUCCESS="PASSWORD_RESET_EMAIL_SUCCESS"
export const PASSWORD_RESET_EMAIL_FAIL="PASSWORD_RESET_EMAIL_FAIL"

export const UPDATE_USER_DETAILS_REQUEST="UPDATE_USER_DETAILS_REQUEST"
export const UPDATE_USER_DETAILS_SUCCESS="UPDATE_USER_DETAILS_SUCCESS"
export const UPDATE_USER_DETAILS_FAIL="UPDATE_USER_DETAILS_FAIL"