import React from 'react'
import data from './SoftwareCredentials.json'
const SoftwareCre = () => {
  return (
   <>
   <div className="container-fluid mb-5">

  
   <div class="row d-flex flex-row">
 
    {
        data.map((item)=>{
            return(
                <>
                 <div class="col-sm-4"style={{height:'12rem',border:'none'}}>
                <div class="card" style={{border:'none'}}>
      <div class="card-body">
        <h5 class="card-title">{item.title}</h5>
        <p class="card-text" >{item.description}</p>
        
</div>
    </div>
    </div>
                
                </>
            )
        })
    }
   </div>
   </div>
 
   </>
  )
}

export default SoftwareCre