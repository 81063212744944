import React, { useEffect } from 'react'
import data from'./happy.json'
import './happy.css'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Slider from "react-slick";
const HappyCustomers = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 6,
    autoplay:true,
    slidesToScroll: 1,
    initialSlide: 6,
    transition:2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

// console.log(categories,'categories');
const params=useParams()
const dispatch=useDispatch()
useEffect(() => {

}, [])




return (
<>
<div className="container-fluid">
<div className="row justify-content-between" style={{height:'auto'}}>
  <h3 className='pic-pic'>Our Happy Customers</h3>
<Slider {...settings}>
{
data && data.map((item,i)=>{
const {_id,name,image}=item
return(
    <>
     <div className="cards  mx-auto main-feature " key={i}
     
     style={{width:'160px', height:'auto',border:'none',marginTop:'5px',background:'white'}}>
    
<img className="card-img-top  object-fit-contain mt-1 mx-auto" 
src={`${image}`}
// src={`/uploads/category/${categoryImage&&categoryImage}`} 
alt={name}/>
<div className="card-body">

<div className="card-title" >

</div>
</div>


</div>

</>
)

})}
</Slider>
</div>
</div>
</>
)
}

export default HappyCustomers