import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import { useNavigate, useParams } from 'react-router-dom'

const UpdateTestmonial = () => {
    const [singleMonial, setsingleMonial] = useState('')
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const {id}=useParams()
    const alert=useAlert()
    const Navigate=useNavigate()
    // console.log(id);
    const gettest=async()=>{
        const {data}=await axios.get(`/admin/testmonial/${id}`)
      setsingleMonial(data.test)
    }
    useEffect(() => {
   gettest()
    }, [])
const postData=async()=>{
    const config = { headers: { "Content-Type": "application/json" } };
    const {data,status}=await axios.put(`/admin/update/testmonial/${id}`,{name,description})
    console.log(data,status); 

if(status===200){
  alert.success('updated succesfully')
  Navigate('/')
}}

    
  return (
    
    <>

    {/* <input type='text' value={name} placeholder={singleMonial.name} onChange={(e)=>setName(e.target.value)}/> */}
    <input type='textarea' value={description} placeholder={singleMonial.description} onChange={(e)=>setDescription(e.target.value)}/>
    <button className='btn btn-danger' onClick={postData}>submit</button>
    </>
  )
}

export default UpdateTestmonial