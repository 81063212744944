import { SERVICE_UPLOAD_FAIL, SERVICE_UPLOAD_REQUEST, SERVICE_UPLOAD_SUCCESS, GET_SERVICE_FAIL, 
    GET_SERVICE_REQUEST, GET_SERVICE_SUCCESS, GET_FEATURE_REQUEST, GET_FEATURE_SUCCESS, GET_FEATURE_FAIL } from '../constant/serviceConstant';
export const postServiceReducer = (state = { service: [] }, action) => {
    switch (action.type) {
        case SERVICE_UPLOAD_REQUEST:
         
            return {
              loading: true,
              service: [],
            }

case SERVICE_UPLOAD_SUCCESS:
    
    return{
        loading:false,
        service:action.payload,
    }
    case SERVICE_UPLOAD_FAIL:
        console.log(action.payload);
        return{
            loading:false,
            service:action.payload
        }


            default:
                return state;}}
                // get service reducer
                export const getServiceReducer = (state = { service: [] }, action) => {
                    switch (action.type) {
                        case GET_SERVICE_REQUEST:
                         
                            return {
                              loading: true,
                              service: [],
                            }
                        case GET_SERVICE_SUCCESS:
                         console.log(action.payload);
                            return {
                              loading: false,
                              service:action.payload,
                            }
                        case GET_SERVICE_FAIL:
                         
                            return {
                              loading: false,
                              service: action.payload,
                            }
                        
                            default:
                                return state
                        }

                }
                export const getFeatureReducer = (state = { feature: [] }, action) => {
                    switch (action.type) {
                        case GET_FEATURE_REQUEST:
                         
                            return {
                              loading: true,
                              feature: [],
                            }
                        case GET_FEATURE_SUCCESS:
                         console.log(action.payload);
                            return {
                              loading: false,
                              feature:action.payload,
                            }
                        case GET_FEATURE_FAIL:
                         
                            return {
                              loading: false,
                              service: action.payload,
                            }
                        
                            default:
                                return state
                        }

                }
                export const getSingleFeatureReducer = (state = {   singleFeature: {} }, action) => {
                    switch (action.type) {
                        case GET_FEATURE_REQUEST:
                         
                            return {
                              loading: true,
                              singleFeature: {},
                            }
                        case GET_FEATURE_SUCCESS:
                         console.log(action.payload);
                            return {
                              loading: false,
                              singleFeature:action.payload,
                            }
                        case GET_FEATURE_FAIL:
                         
                            return {
                              loading: false,
                              service: action.payload,
                            }
                        
                            default:
                                return state
                        }

                }