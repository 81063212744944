import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getSingleTestmonialAction } from '../redux/action/testmonialAction'
import { useDispatch, useSelector } from 'react-redux'

const SingleTestimonial = () => {
    const {id}=useParams()
const {testmonial}=useSelector(state=>state.getSingleTestmonialReducer)
console.log(testmonial);
    const dispatch=useDispatch()
    useEffect(() => {
 dispatch(getSingleTestmonialAction(id))
    }, [dispatch,id])
    
  return (
  <>
  <div className="card bg-dark text-light mx-auto" style={{height:'auto',width:'100%'}}>
    <h5 className="card-title text-center">{testmonial.name}</h5>
  <img className="card-img-top text-center mx-auto" src={`/uploads/testmonial/${testmonial.file}`} alt="Card image cap"
  style={{height:'200px',width:'250px'}}
  />
  <div className="card-body">
  
    <h5 className="card-title text-center">{testmonial.title}</h5>
  
    <p className="card-text text-left">{testmonial.description}</p>

  </div>
</div>
  </>
  )
}

export default SingleTestimonial