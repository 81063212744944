import { TESTMONIAL_UPLOAD_FAIL, TESTMONIAL_UPLOAD_REQUEST, TESTMONIAL_UPLOAD_SUCCESS,
    GET_TESTMONIAL_FAIL, GET_TESTMONIAL_REQUEST, GET_TESTMONIAL_SUCCESS,
    UPDATE_TESTMONIAL_FAIL, UPDATE_TESTMONIAL_REQUEST, UPDATE_TESTMONIAL_SUCCESS,
    GET_SINGLE_TESTMONIAL_FAIL, GET_SINGLE_TESTMONIAL_REQUEST, GET_SINGLE_TESTMONIAL_SUCCESS,
    DELETE_TESTMONIAL_FAIL, DELETE_TESTMONIAL_REQUEST, DELETE_TESTMONIAL_SUCCESS
} from '../constant/testmonialConstant';
export const getTestmonialReducer = (state = { testmonial: [] }, action) => {
    switch (action.type) {
        case GET_TESTMONIAL_REQUEST:
         
            return {
              loading: true,
              testmonial: [],
            }
        case GET_TESTMONIAL_SUCCESS:
         
            return {
              loading: false,
              testmonial:action.payload,
            }
        case GET_TESTMONIAL_FAIL:
         
            return {
              loading: false,
              testmonial: action.payload,
            }
        
            default:
                return state
        }

}
export const getSingleTestmonialReducer = (state = { testmonial: {} }, action) => {
    switch (action.type) {
        case GET_SINGLE_TESTMONIAL_REQUEST:
         
            return {
              loading: true,
              testmonial: {},
            }
        case GET_SINGLE_TESTMONIAL_SUCCESS:
         
            return {
              loading: false,
              testmonial:action.payload,
            }
        case GET_SINGLE_TESTMONIAL_FAIL:
         
            return {
              loading: false,
              testmonial: action.payload,
            }
        
            default:
                return state
        }

}
