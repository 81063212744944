import axios from 'axios'
import { TESTMONIAL_UPLOAD_FAIL, TESTMONIAL_UPLOAD_REQUEST, TESTMONIAL_UPLOAD_SUCCESS,
    GET_TESTMONIAL_FAIL, GET_TESTMONIAL_REQUEST, GET_TESTMONIAL_SUCCESS,
    GET_SINGLE_TESTMONIAL_FAIL, GET_SINGLE_TESTMONIAL_REQUEST, GET_SINGLE_TESTMONIAL_SUCCESS,
    UPDATE_TESTMONIAL_FAIL, UPDATE_TESTMONIAL_REQUEST, UPDATE_TESTMONIAL_SUCCESS,
    DELETE_TESTMONIAL_FAIL, DELETE_TESTMONIAL_REQUEST, DELETE_TESTMONIAL_SUCCESS
} from '../constant/testmonialConstant';
export const getTestmonialAction = () => async(dispatch)=>{
    dispatch({type:GET_TESTMONIAL_REQUEST})
    try {
        const {data}=await axios.get('/get/testmonial')
        // console.log(data);
    dispatch({type:GET_TESTMONIAL_SUCCESS,
        payload:data.testmonial})
    } catch (error) {
        dispatch({type:GET_TESTMONIAL_FAIL,
            payload: error.response.data,
        })}}
export const getSingleTestmonialAction = (id) => async(dispatch)=>{
    dispatch({type:GET_SINGLE_TESTMONIAL_REQUEST})
    try {
        const {data}=await axios.get(`/get/single/testmonial/${id}`)
        console.log(data.test);
    dispatch({type:GET_SINGLE_TESTMONIAL_SUCCESS,
        payload:data.test})
    } catch (error) {
        dispatch({type:GET_SINGLE_TESTMONIAL_FAIL,
            payload: error.response.data,
        })}}