import React, { useEffect, useState } from 'react'
import './weoffer.css'
import axios from 'axios'
import Typed from 'react-typed'
import { useDispatch, useSelector } from 'react-redux'
import { getBannerAction } from '../redux/action/bannerAction'
import { getUserAction } from '../redux/action/userIp'
const WeOffer = () => {
const {userip}=useSelector(state=>state.getuserIpReducer)
console.log(userip);
const dispatch=useDispatch()
useEffect(() => {
  dispatch(getUserAction())
}, [dispatch])

let abc;
if(userip==='PK'){
  abc='PKR-6000/-'
}
else if(userip==='FR') { 
  abc='EURO-40'
}
else if(userip==='AE') { 
  abc='AED-100'
}
else if(userip==='SA') { 
  abc='SAR-100'
}
else {
  abc='$40'
}




  return (
<>
{/* {userip && userip.length>0? userip:null} */}
<div className="container-fluid h-75">
  <div className="row d-flex flex-row">
  
  <div className="col-3  weoffer"></div>

{/* <button className='btn btn-primary' onClick={()=>dispatch(getUserAction)}>get ip</button> */}
<div className='col-9 offer-title d-flex flex-row'>
  {/* <marquee>we host your web site just for   */}
  {/* <div className='text-primary'> */}
    {/* {userip==='PK'? 'RS-6000/-':'$40'
  ?userip==='AE':'AED-100'
  ?userip==='SA':'SA-100'
  ?userip==='AU':'AUD-40'
  ?userip==='US':'USD-40'
  ?userip==='GB':'GBP-30'
  ?userip==='IN':'INR-5000'
  
    } */}
    {/* </div> */}
  
   {/* only</marquee> </div> */}
 <Typed strings={[
`we host your website just for ${abc}  only`,

 ]}
 typeSpeed={350}

 backSpeed={0}
 loop/>
   </div>

     
 
  </div>
</div>

</>
  )
}

export default WeOffer